<template>
  <div>
    <div id="titlebar" class="blue">
      <div class="container">
        <div class="row">
          <div class="col-md-12">

            <h2>Edit Profile</h2>
            <p class="margin-bottom-0">Complete your donor account profile<br></p>

            <!-- Breadcrumbs -->
            <nav id="breadcrumbs" class="dark">
              <ul>
                <li><a href="#" v-on:click.prevent="$router.go(-1)">Back</a></li>
                <li>Edit</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <!-- Dashboard Box -->
        <div class="col-xl-12">
          <div v-if="donorInfo" class="dashboard-box margin-top-0">

            <div class="content with-padding padding-bottom-0">
              <form action="?" @submit.prevent="saveDonorInfo">
                <div class="row">

                  <div class="col-auto">
                    <div class="submit-field">
                      <h5>Profile Photo</h5>
                      <div v-if="isPhotoUploading"
                           class="avatar-wrapper avatar-loading primary-color text-center"
                           title="Change Photo">
                        <font-awesome-icon :icon="['fas', 'spinner']" spin size="3x"/>
                      </div>
                      <div v-else
                           class="avatar-wrapper"
                           v-tippy="{ placement : 'bottom',  arrow: true}"
                           v-on:click.prevent="$refs.fileUpload.click()"
                           title="Change Photo">
                        <img class="profile-pic"
                             :src="donorInfo.profile_photo?donorInfo.profile_photo:require('../Parts/user-avatar-placeholder.png')"
                             alt=""/>
                      </div>
                      <input class="file-upload"
                             type="file"
                             ref="fileUpload"
                             @change="onAvatarChanged"
                             accept="image/*"/>
                    </div>
                  </div>

                  <div class="col">
                    <div class="row">

                      <div class="col-md-6">
                        <div class="submit-field">
                          <h5>First Name</h5>
                          <input type="text"
                                 class="with-border"
                                 v-model="donorInfo.first_name"
                                 required
                                 placeholder="First Name">
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="submit-field">
                          <h5>Last Name</h5>
                          <input type="text"
                                 class="with-border"
                                 v-model="donorInfo.last_name"
                                 required
                                 placeholder="Last Name">
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="submit-field">
                          <h5>Email Address</h5>
                          <input type="text"
                                 class="with-border"
                                 :value="donorInfo.email"
                                 readonly
                                 placeholder="Email Address">
                        </div>
                      </div>

                      <div class="col-md-6"></div>

                      <div class="col-md-6">
                        <div class="submit-field">
                          <h5>Phone Number</h5>
                          <masked-input
                              type="tel"
                              inputmode="numeric"
                              class="with-border"
                              pattern="[0-9]{3}-[0-9]{3}-(([0-9]{3}\s)|[0-9]{4})"
                              v-model="donorInfo.phoneNumber"
                              :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                              :guide="true"
                              :placeholderChar="'\u2000'"
                              placeholder="xxx-xxx-xxxx"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <!-- Account Type -->
                        <div class="submit-field" v-if="donorInfoInitial.phoneNumber">
                          <h5>Text Notifications</h5>
                          <div class="account-type">
                            <div>
                              <input type="radio"
                                     id="messages-on-radio"
                                     class="account-type-radio"
                                     v-model="donorInfo.phoneEnabled"
                                     :value="true"
                              />
                              <label for="messages-on-radio" class="ripple-effect-dark"
                              ><i><font-awesome-icon :icon="['far', 'bell-on']" size="sm"/></i> ON </label>
                            </div>

                            <div>
                              <input type="radio"
                                     id="messages-off-radio"
                                     v-model="donorInfo.phoneEnabled"
                                     :value="false"
                                     class="account-type-radio"/>
                              <label for="messages-off-radio" class="ripple-effect-dark"
                              ><i><font-awesome-icon :icon="['far', 'bell-slash']" size="sm"/></i> OFF </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <slide-up-down class="slide-up-down" :class="{'is-active': donorInfoChanged}"
                                     :active="donorInfoChanged">
                        <div class="col-xl-12">
                          <div class="submit-field">
                            <button type="submit"
                                    v-ripple-dark
                                    class="button big button-sliding-icon"
                            >Save Changes <i class="margin-left-3"
                            ><font-awesome-icon :icon="['far', 'save']" /></i></button>
                          </div>
                        </div>
                      </slide-up-down>

                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-if="donorInfo" class="col-xl-12">
          <div class="dashboard-box">

            <div class="content">
              <ul class="fields-ul">

                <li>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="submit-field">
                        <h5>Birthday</h5>
                        <masked-input
                            type="text"
                            inputmode="numeric"
                            class="with-border"
                            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                            v-model="donorInfo.birthday"
                            :mask="[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]"
                            :guide="true"
                            :placeholderChar="'\u2000'"
                            placeholder="mm/dd/yyyy"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <!-- Account Type -->
                      <div v-if="genders.length"
                           class="submit-field">
                        <h5>Gender</h5>
                        <bootstrap-select v-model="donorInfo.gender_id"
                                          :options="genders"
                                          trackBy="id"
                                          label="name"
                                          class="default"
                        ></bootstrap-select>
                      </div>
                    </div>

                    <slide-up-down class="slide-up-down" :class="{'is-active': donorInfoChanged2}"
                                   :active="donorInfoChanged2">
                      <div class="col-xl-12">
                        <div class="submit-field">
                          <button type="submit"
                                  v-ripple-dark
                                  v-on:click.prevent="saveDonorInfo"
                                  class="button big button-sliding-icon"
                          >Save Changes <i class="margin-left-3"
                          ><font-awesome-icon :icon="['far', 'save']" /></i></button>
                        </div>
                      </div>
                    </slide-up-down>


                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Dashboard Box -->
        <div v-if="address" class="col-xl-12">
          <div class="dashboard-box">

            <div class="content with-padding padding-bottom-10">
              <div class="row">

                <div class="col-md-2">
                  <div class="submit-field">
                    <h5>Address</h5>
                    <div>
                      <div>
                        <input class="with-border"
                               v-model="address.street_number"
                               type="text"
                               placeholder="Street Number">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="submit-field">
                    <h5>&nbsp;</h5>
                    <div class="input-with-icon">
                      <div>
                        <input class="with-border"
                               v-model="address.route"
                               type="text"
                               placeholder="Address">
                      </div>
                      <i><font-awesome-icon :icon="['far', 'map-marker-alt']" size="sm" /></i>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="submit-field">
                    <h5>Address 2 (Room/Unit/Suite)</h5>
                    <div class="input-with-icon">
                      <div>
                        <input class="with-border"
                               v-model="address.premise"
                               type="text"
                               placeholder="Optional">
                      </div>
                      <i><font-awesome-icon :icon="['far', 'map-marker-plus']" size="sm" /></i>
                    </div>
                  </div>
                </div>


                <div class="col-md-4">
                  <div class="submit-field">
                    <h5>City</h5>
                    <div class="input-with-icon">
                      <div>
                        <input class="with-border"
                               v-model="address.locality"
                               type="text"
                               placeholder="City">
                      </div>
                      <i><font-awesome-icon :icon="['fas', 'city']" size="xs" /></i>
                    </div>
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="submit-field">
                    <h5>State, Zip Code & Country</h5>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="input-with-icon">
                          <input class="with-border"
                                 v-model="address.administrative_area_level_1"
                                 type="text"
                                 placeholder="">
                          <i class="currency">State</i>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-with-icon">
                          <input class="with-border"
                                 v-model="address.postal_code"
                                 type="text"
                                 placeholder="">
                          <i class="currency">Zip Code</i>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="input-with-icon">
                          <input class="with-border"
                                 v-model="address.country"
                                 type="text"
                                 placeholder="">
                          <i class="currency">Country</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <slide-up-down class="slide-up-down" :class="{'is-active': addressChanged}"
                               :active="addressChanged">
                  <div class="col-xl-12">
                    <div class="submit-field">
                      <a href="#"
                         v-on:click.prevent="saveAddress"
                         v-ripple-dark
                         class="button big button-sliding-icon"
                      >Save Changes <i class="margin-left-3"
                      ><font-awesome-icon :icon="['far', 'save']" /></i></a>
                    </div>
                  </div>
                </slide-up-down>
              </div>
            </div>
          </div>
        </div>

        <!-- Dashboard Box -->
        <div v-if="employment" class="col-xl-12 margin-bottom-50">
          <div class="dashboard-box">

            <div class="content">
              <ul class="fields-ul">

                <li>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="submit-field">
                        <h5>Employer</h5>
                        <input type="text"
                               class="with-border"
                               v-model="employment.employer_name"
                               placeholder="Employer">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="submit-field">
                        <h5>Job Title</h5>
                        <input type="text"
                               class="with-border"
                               v-model="employment.occupation"
                               placeholder="Position/Title">
                      </div>
                    </div>

                    <slide-up-down class="slide-up-down" :class="{'is-active': employmentChanged}"
                                   :active="employmentChanged">
                      <div class="col-xl-12">
                        <div class="submit-field">
                          <a href="#"
                             v-on:click.prevent="saveEmployment"
                             v-ripple-dark
                             class="button big button-sliding-icon"
                          >Save Changes <i class="margin-left-3"
                          ><font-awesome-icon :icon="['far', 'save']" /></i></a>
                        </div>
                      </div>
                    </slide-up-down>

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="confirmPhoneModal"
                          :id="'sign-in-dialog'"
                          v-on:close="$emit('update-donor-details')"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <div class="sign-in-form">
        <ul class="popup-tabs-nav">
          <li><a href="#phone-number">Phone Number</a></li>
        </ul>
        <div class="popup-tabs-container">
          <div class="popup-tab-content" id="phone-number">
            <confirm-phone-form v-if="donorInfo"
                                :phone-number="donorInfo.phoneNumber"
                                :charityInfo="charityInfo"
                                v-on:update-donor-details="$refs.confirmPhoneModal.close();$emit('update-donor-details')"
                                v-on:phone-verified="$refs.confirmPhoneModal.close();$emit('update-donor-details')"
            ></confirm-phone-form>
          </div>
        </div>
      </div>
    </magnific-popup-modal>

  </div>
</template>

<script>
  import {
    putProfilePhoto,
    confirmPhotoUploaded,
    patchPhoneEnable,
    addPhone,
    deletePhone,
    userAccount
  } from '../../utils/api';
  import SlideUpDown from 'vue-slide-up-down';
  import MaskedInput from 'vue-text-mask'
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import ConfirmPhoneForm from '../Parts/confirm-phone-form.vue'
  import BootstrapSelect from '../BootstrapSelect'
  import Snackbar from 'node-snackbar';
  import {getFullAddress, searchAddress} from "../../utils/maps";

  const moment = require('moment');

  export default {
    name: 'AccountEdit',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    metaInfo() {
      return {
        title: 'Edit Profile',
      }
    },
    components: {
      SlideUpDown,
      MaskedInput,
      MagnificPopupModal,
      ConfirmPhoneForm,
      BootstrapSelect
    },
    mounted() {
      this.updateDonorInfo();

      userAccount.getEmployment()
        .then(employment => {
          this.setEmployment(employment)
        })
        .catch(() => this.setEmployment({employer_name: '', occupation: ''}));

      //https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      userAccount.getAddress()
        .then(address => this.setAddress(address))
        .catch(() => this.setAddress({
          country: '',
          administrative_area_level_1: '',
          locality: '',
          street_number: '',
          route: '',
          premise: '',
          postal_code: ''
        }));

      userAccount.getAllGenders().then(genders => {
        this.genders = genders;
      });
    },
    methods: {
      saveEmployment() {
        userAccount.saveEmployment(this.employment.employer_name, this.employment.occupation)
          .then((res) => {
            this.setEmployment(this.employment);
            Snackbar.show({
              text: res.message,
              pos: 'top-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: this.charityInfo.primary_color
            });
          })
          .catch(() => alert('An error has occurred, please try again.'))
      },
      saveAddress() {
        let latitude = null;
        let longitude = null;

        searchAddress(getFullAddress(this.address)).then(res => {
          if (res && res.geometry && res.geometry.location) {
            latitude = res.geometry.location.lat();
            longitude = res.geometry.location.lng();
          }
        }).finally(() => {
          userAccount.saveAddress(
            this.address.country,
            this.address.administrative_area_level_1,
            this.address.locality,
            this.address.street_number,
            this.address.route,
            this.address.premise,
            this.address.postal_code,
            '',
            latitude,
            longitude
          ).then((res) => {
            this.setAddress(this.address);
            Snackbar.show({
              text: res.message,
              pos: 'top-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: this.charityInfo.primary_color
            });
          })
            .catch(() => alert('An error has occurred, please try again.'))
        });
      },
      setEmployment(info) {
        this.employment = Object.assign({}, info);
        this.employmentInitial = Object.assign({}, info);
      },
      setAddress(info) {
        this.address = Object.assign({}, info);
        this.addressInitial = Object.assign({}, info);
      },
      updateDonorInfo() {
        if (this.donorDetail) {
          this.donorDetail.phoneEnabled = !!this.donorDetail.phoneEnabled;
          this.donorDetail.enablePush = !!this.donorDetail.enablePush;
          this.donorDetail.enableFlash = !!this.donorDetail.enableFlash;
          this.donorDetail.gender_id = this.donorDetail.gender_id || 0;
          this.donorDetail.gender = this.donorDetail.gender || '';
          this.donorDetail.phoneNumber = this.donorDetail.phoneNumber || '';

          let birthday = this.donorDetail.birthday || '';
          if (birthday !== '') {
            birthday = moment(birthday, 'YYYY-MM-DD').format('MM/DD/YYYY');
          }
          this.donorDetail.birthday = birthday;

          this.donorInfo = Object.assign({}, this.donorDetail);
          this.donorInfoInitial = Object.assign({}, this.donorDetail);

        }
      },
      async saveDonorInfo() {
        const params = ['first_name', 'last_name', 'gender_id', 'birthday'];

        let diff = {};
        for (let i = 0; i < params.length; i++) {
          if (this.donorInfo[params[i]] !== this.donorInfoInitial[params[i]]) {
            diff[params[i]] = this.donorInfo[params[i]];
          }
        }

        if (Object.entries(diff).length !== 0) {
          userAccount.patchAccount(diff).then((res) => {
            Snackbar.show({
              text: res.message,
              pos: 'top-center',
              showAction: false,
              actionText: "Dismiss",
              duration: 3000,
              textColor: '#fff',
              backgroundColor: this.charityInfo.primary_color
            });
            this.$emit('update-donor-details');
          })
        }

        const phoneNumber = this.donorInfo.phoneNumber.replace(/-/g, '');
        if (phoneNumber !== this.donorInfoInitial.phoneNumber) {
          if (phoneNumber !== '') {

            if (this.donorInfoInitial.phoneNumber !== '') {
              await deletePhone();
              await new Promise(resolve => setTimeout(resolve, 300));
            }

            addPhone(phoneNumber).then(() => {
              this.$refs.confirmPhoneModal.open();
            }).catch(err => {
              //show error and skip this step
              alert(err.responseJSON && err.responseJSON.error || 'Can not send confirmation code to the phone number');
              this.$emit('update-donor-details');
            });
          } else {
            deletePhone().then(() => this.$emit('update-donor-details'));
          }
        }

        if (this.donorInfo.phoneEnabled !== this.donorInfoInitial.phoneEnabled) {
          patchPhoneEnable(this.donorInfo.phoneEnabled).then(() => this.$emit('update-donor-details'));
        }
      },
      checkPhotoLoading() {
        userAccount.getPhotoStatus().then(status => {
          if (status.status === 'complete' || status.status === 'failed') {
            //this.donorInfo.profile_photo = false;
            this.$emit('update-donor-details');
          } else {
            setTimeout(() => this.checkPhotoLoading(), 500);
          }
        });
      },
      onAvatarChanged (e) {
        const file = e.target.files[0];
        const self = this;
        if (file) {
          self.isPhotoUploading = true;
          userAccount.getUploadUriAndToken().then(uploadInfo => {
            putProfilePhoto(uploadInfo.upload_uri, file).then(() => {
              confirmPhotoUploaded(uploadInfo.upload_token).then(() => {
                self.checkPhotoLoading();
              }).catch(() => self.isPhotoUploading = false);
            }).catch(() => self.isPhotoUploading = false);
          }).catch(() => self.isPhotoUploading = false);
        }
      },
    },
    computed: {
      employmentChanged() {
        return JSON.stringify(this.employmentInitial) !== JSON.stringify(this.employment);
      },
      addressChanged() {
        return JSON.stringify(this.addressInitial) !== JSON.stringify(this.address);
      },
      donorInfoChanged() {

        const filterParams = (({ email, first_name, last_name, phoneEnabled, phoneNumber }) =>
            ({ email, first_name, last_name, phoneEnabled, phoneNumber }));

        const info = filterParams(this.donorInfo);
        info['phoneNumber'] = info['phoneNumber'].replace(/-/g, '');

        return JSON.stringify(filterParams(this.donorInfoInitial)) !== JSON.stringify(info);
      },
      donorInfoChanged2() {

        const filterParams = (({ gender_id, birthday }) => ({ gender_id, birthday }));

        return JSON.stringify(filterParams(this.donorInfoInitial)) !== JSON.stringify(filterParams(this.donorInfo));
      },
    },
    watch: {
      'donorDetail' () {
        this.updateDonorInfo();
        this.isPhotoUploading = false;
      },
    },
    data() {
      return {
        donorInfo: null,
        donorInfoInitial: null,
        isPhotoUploading: false,
        employment: null,
        employmentInitial: null,
        address: null,
        addressInitial: null,
        genders: []
      };
    },
  }
</script>