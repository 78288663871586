<template>
  <div class="container margin-top-30">
    <div class="row">
      <div class="col-xl-5 offset-xl-3">

        <div class="login-register-page">
          <div v-if="isSent" class="welcome-text">
            <h3>Recovery email sent</h3>
            <span>Check your email for password recovery instructions</span>
          </div>

          <div v-else>
            <div class="welcome-text">
              <h3>Reset your Password</h3>
              <span>Don't have an account?
              <router-link :to="{name: 'Register'}">Sign Up</router-link>
            </span>
            </div>

            <form method="post" @submit.prevent="submitEmail">
              <div class="input-with-icon-left no-border"
                   title="Make sure it's the email you signed up with"
                   v-tippy="{ placement : 'top',  arrow: true}">
                <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
                <input type="email"
                       class="input-text no-border"
                       name="email"
                       placeholder="Email Address"
                       v-model="email"
                       required
                />
              </div>

              <slide-up-down class="slide-up-down" :class="{'is-active': sentIsError}" :active="sentIsError">
                <div class="notification error">
                  <p>{{sentError}}</p>
                  <a href="#" v-on:click.prevent="sentIsError = false" class="close"></a>
                </div>
              </slide-up-down>

              <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                      type="submit"
              >Reset Password <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {resetPassword} from '../../utils/api'

  import SlideUpDown from 'vue-slide-up-down'

  export default {
    name: 'ForgotPassword',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    mounted() {
      if (this.$route.query && 'email' in this.$route.query) {
        this.email = this.$route.query.email;
      }
    },
    methods: {
      submitEmail() {
        resetPassword(this.email, this.charityInfo.charity_id)
          .then(() => {
            this.isSent = true;
          })
          .catch(err => {
            this.sentError = err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
            this.sentIsError = true;
          });
      }
    },
    components: {
      SlideUpDown,
    },
    watch: {
      'email': function () {
        this.sentIsError = false;
      },
    },
    data() {
      return {
        email: '',
        sentError: '',
        sentIsError: false,
        isSent: false,
      };
    },
  }
</script>
