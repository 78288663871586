<template>
  <div class="account-page">
    <div class="single-page-header freelancer-header" v-bind:style="styleBg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="single-page-header-inner">
              <div v-if="donorDetail" class="left-side">
                <div class="header-image freelancer-avatar">
                  <router-link :to="{name: 'AccountEdit'}"
                  ><img
                      :src="donorDetail.profile_photo?donorDetail.profile_photo:require('../Parts/user-avatar-placeholder.png')"
                      alt=""></router-link>
                </div>
                <div class="header-details">
                  <h3>{{donorDetail.first_name}} {{donorDetail.last_name}} <span>{{donorDetail.email}}</span></h3>
                  <ul v-if="donorDetail.phoneNumber">
                    <li v-if="donorDetail.phoneEnabled">
                      <div class="verified-badge-with-title">
                        <i><font-awesome-icon :icon="['fal', 'check']"/></i>
                        Messages ON</div>
                    </li>
                    <li>{{donorDetail.phoneNumber.replace(/(\d{3})(\d{3})(\d{4,10})/, '$1-$2-$3')}}</li>
                  </ul>
                </div>
              </div>
              <div class="right-side">
                <div v-if="donationHistory.length" class="salary-box">
                  <div class="salary-type">Total Donated</div>
                  <div class="salary-amount">
                    ${{(donationSummary.custom + donationSummary.recurring + donationSummary.roundup) / 100 |
                    formatMoney}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">

        <!-- Content -->
        <div class="col-xl-4 col-md-5 content-right-offset">
          <!-- Profile Overview -->
          <div v-if="donationHistory.length" class="profile-overview">
            <div class="overview-item">
              <router-link :to="{name: 'Donate', query: {'selected-method': DonationTypes.ONE_TIME}}">
                <strong>${{donationSummary.custom / 100 | formatMoney(2)}}</strong><span>One-Time</span>
              </router-link>
            </div>

            <div class="overview-item">
              <router-link :to="{name: 'MonthlyDonations'}">
                <strong>${{donationSummary.recurring / 100 | formatMoney(2)}}</strong><span>Monthly</span>
              </router-link>
            </div>

            <div class="overview-item">
              <router-link :to="{name: 'AccountRoundUps'}">
                <strong>${{donationSummary.roundup / 100 | formatMoney(2)}}</strong><span>Round-Ups</span>
              </router-link>
            </div>
          </div>

          <!-- Button -->
          <router-link :to="{name: 'AccountEdit'}"
                       class="apply-now-button margin-bottom-35"
          >Edit Profile
            <i><font-awesome-icon :icon="['far', 'edit']" /></i>
          </router-link>

          <!-- Sidebar Widget -->
          <div class="sidebar-widget">
            <div class="job-overview">
              <div class="job-overview-inner">
                <ul v-if="donorDetail">
                  <li>
                    <i class="text-primary"><font-awesome-icon icon="arrow-right"/></i>
                    <h5>
                      <strong class="fw-600">
                        <router-link :to="{name: 'AccountEdit'}">My Profile</router-link>
                      </strong>
                    </h5>
                  </li>
                  <li>
                    <i class="text-primary"><font-awesome-icon icon="arrow-right"/></i>
                    <h5>
                      <strong class="fw-600">
                        <router-link :to="{name: 'PaymentMethods'}">Payment Methods</router-link>
                      </strong>
                    </h5>
                  </li>
                  <li>
                    <i class="text-primary"><font-awesome-icon icon="arrow-right"/></i>
                    <h5>
                      <strong class="fw-600">
                        <router-link :to="{name: 'Account'}">Donation History</router-link>
                      </strong>
                    </h5>
                  </li>
                  <li>
                    <i class="text-primary"><font-awesome-icon icon="arrow-right"/></i>
                    <h5>
                      <strong class="fw-600">
                        <router-link :to="{name: 'MonthlyDonations'}">Monthly Gifts</router-link>
                      </strong>
                    </h5>
                  </li>
                  <li>
                    <i class="text-primary"><font-awesome-icon icon="arrow-right"/></i>
                    <h5>
                      <strong class="fw-600">
                        <router-link :to="{name: 'RoundUpDonation'}">Round-Ups</router-link>
                      </strong>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="fullAddress" class="single-page-section">
            <h3 class="margin-bottom-5">Your Address</h3>
            <a v-on:click.prevent="" href="#">{{fullAddress}}</a>
            <div v-if="showMap" id="single-job-map-container" class="margin-top-25">
              <div id="singleListingMap"></div>
              <a href="#" id="streetView"><i><font-awesome-icon :icon="['fal', 'street-view']" /></i> Street View</a>
            </div>
          </div>
        </div>

        <div class="col-xl-8 col-md-7">
          <div class="dashboard-box margin-top-0">

            <!-- Headline -->
            <div class="headline headline-donation">
              <h3><i class="text-primary"><font-awesome-icon :icon="['far', 'chart-bar']"/></i> Donation History</h3>
            </div>

            <div v-if="donationHistoryLoaded" class="content">
              <ul v-if="donationHistory.length === 0" class="dashboard-box-list">
                <li>
                  <div class="boxed-list-item">
                    <!-- Content -->
                    <div class="item-content">
                      <h4>No Donations Yet</h4>
                      <div class="item-details">
                        <div class="detail-item">Want to make your first donation?</div>
                      </div>
                      <span><em></em></span>
                      <div class="item-description">
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <router-link :to="{name: 'Donate'}" class="popup-with-zoom-anim button " v-ripple-dark>
                    Make a Donation
                  </router-link>
                </li>
              </ul>
              <transition-group v-else name="list-complete" tag="ul" class="dashboard-box-list">
                <li v-for="(donation) in donationsShow"
                    :key="`${donation.created_at}_${donation.charge_id}`"
                    class="list-complete-item"
                >
                  <div class="boxed-list-item">
                    <!-- Content -->
                    <div class="item-content">
                      <span v-if="donation.status === 'refunded'"
                            class="dashboard-status-button margin-left-0 green"
                      ><i><font-awesome-icon :icon="['far', 'undo']" size="sm"/></i> Refunded</span>
                      <h4>${{(donation.amount) / 100 | formatMoney}} Donation</h4>
                      <div class="item-details">
                        <div class="detail-item">
                          You made a <strong>{{donation.donation_type.replace('custom', 'One-Time').replace('recurring',
                          'Monthly').replace('roundup', 'Round-Up')}}</strong>
                          donation to
                          <router-link
                              :to="{name: 'Campaign', params:{'campaignId':donation.campaign_id?donation.campaign_id:0}}"
                          ><b>{{donation.campaign_id ? donation.campaign_name : 'Area of Greatest Need'}}</b>
                          </router-link>
                          on <strong>{{donation.created_at | dateFormat("MMMM D, YYYY")}}</strong></div>
                      </div>
                      <span><em>{{donation.created_at | dateFromNow}}</em></span>
                      <div v-if="donation.note" class="item-description">
                        <p style="white-space: pre-wrap">"{{donation.note}}"<br>
                          <a href="#"
                             v-on:click.prevent="editDonationId = donation.id;$refs.donationNote.open(donation.note, donation.is_note_public)"
                          ><sup>- Edit Note</sup></a></p>
                      </div>
                      <div v-if="donation.donation_metadata && donation.donation_metadata.honor"
                           class="item-description">
                        <div v-if="donation.donation_metadata.honor.from">
                          <b>From:</b> {{donation.donation_metadata.honor.from}}
                        </div>
                        <div v-if="donation.donation_metadata.honor.name">
                          <b>In {{donation.donation_metadata.honor.type === 'memory' ? 'Memory' : 'Honor'}}:</b>
                          {{donation.donation_metadata.honor.name}}
                        </div>
                        <div v-if="donation.donation_metadata.honor.message">
                          <b>Message:</b> {{donation.donation_metadata.honor.message}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <router-link :to="{name: 'DonationHistoryReceipt', params: {'id': donation.charge_id}}"
                               class="popup-with-zoom-anim button ripple-effect"
                  > View Receipt
                  </router-link>
                </li>
              </transition-group>
            </div>
          </div>
          <div class="centered-button margin-top-35">
            <a href="#"
               v-if="donationsLimit<donationHistory.length"
               v-on:click.prevent="donationsLimit+=3"
               class="button button-sliding-icon"
            >View More <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></a>
          </div>
        </div>
      </div>
      <div class="margin-top-15"></div>
    </div>

    <DonationNote ref="donationNote"
                  v-on:save-note="donationNoteSave"
    ><template v-slot:button>
      <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
              type="submit"
      >Save Changes <i class="margin-left-3"><font-awesome-icon :icon="['far', 'save']" /></i>
      </button>
    </template></DonationNote>

  </div>
</template>

<script>
  import $ from 'jquery';
  import google from 'google';
  import {putDonationNote, userAccount} from '../../utils/api'
  import {CustomMarker, getFullAddress, searchAddress} from '../../utils/maps'
  import DonationNote from '../Donations/donation-note'
  import {mapState, mapActions} from 'vuex'
  import {showNotification} from "../../utils/utils";

  export default {
    name: 'Account',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    metaInfo() {
      return {
        title: 'Donation History',
      }
    },
    components: {
      DonationNote
    },
    mounted() {
      userAccount.getEmployment()
        .then(employment => this.employment = employment)
        .catch(() => this.employment = null);

      userAccount.getAddress()
        .then(address => {
          this.address = address;
          const self = this;

          this.showMap = true;

          if (Number(address.latitude) && Number(address.longitude)) {
            self.$nextTick(() => self.initMap(new google.maps.LatLng(address.latitude, address.longitude)));
          } else {
            searchAddress(self.fullAddress).then(res => {
              if (res && res.geometry && res.geometry.location) {
                self.$nextTick(() => self.initMap(res.geometry.location));
              } else {
                self.showMap = false;
              }
            }).catch(() => self.showMap = false);
          }
        })
        .catch(() => this.address = null);

      this.updateDonations();

      //https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    },
    methods: {
      ...mapActions('donationHistory', [
        'updateDonations',
      ]),
      donationNoteSave(note, is_public) {
        putDonationNote(this.editDonationId, note, is_public).then(() => {
          showNotification(`Donation note has been updated.`, this.charityInfo.primary_color);
          setTimeout(() => this.updateDonations(true), 400);
        })
      },
      initMap(myLatlng) {
        const single_map = new google.maps.Map(document.getElementById('singleListingMap'), {
          zoom: 15,
          center: myLatlng,
          scrollwheel: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          panControl: false,
          navigationControl: false,
          streetViewControl: false,
          styles: [{
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{"color": "#747474"}, {"lightness": "23"}]
          }, {
            "featureType": "poi.attraction",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#f38eb0"}]
          }, {
            "featureType": "poi.government",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ced7db"}]
          }, {
            "featureType": "poi.medical",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ffa5a8"}]
          }, {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#c7e5c8"}]
          }, {
            "featureType": "poi.place_of_worship",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#d6cbc7"}]
          }, {
            "featureType": "poi.school",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#c4c9e8"}]
          }, {
            "featureType": "poi.sports_complex",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#b1eaf1"}]
          }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{"lightness": "100"}]
          }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [{"visibility": "off"}, {"lightness": "100"}]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ffd4a5"}]
          }, {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [{"color": "#ffe9d2"}]
          }, {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [{"visibility": "simplified"}]
          }, {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [{"weight": "3.00"}]
          }, {
            "featureType": "road.local",
            "elementType": "geometry.stroke",
            "stylers": [{"weight": "0.30"}]
          }, {
            "featureType": "road.local",
            "elementType": "labels.text",
            "stylers": [{"visibility": "on"}]
          }, {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [{"color": "#747474"}, {"lightness": "36"}]
          }, {
            "featureType": "road.local",
            "elementType": "labels.text.stroke",
            "stylers": [{"color": "#e9e5dc"}, {"lightness": "30"}]
          }, {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [{"visibility": "on"}, {"lightness": "100"}]
          }, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#d2e7f7"}]}]
        });

        const panorama = single_map.getStreetView();
        panorama.addListener('visible_changed', function () {
          const toggle = panorama.getVisible();
          if (toggle) {
            $('#streetView').css('display', 'none')
          } else {
            $('#streetView').css('display', 'block')
          }
        });

        // Steet View Button
        $('#streetView').click(function (e) {
          e.preventDefault();
          const toggle = panorama.getVisible();
          if (toggle === false) {
            panorama.setOptions({visible: true, position: myLatlng})
          } else {
            panorama.setVisible(false);
          }
        });

        // Custom zoom buttons
        const zoomControlDiv = document.createElement('div');
        new ZoomControl(zoomControlDiv, single_map);

        function ZoomControl(controlDiv, single_map) {

          zoomControlDiv.index = 1;
          single_map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(zoomControlDiv);

          controlDiv.style.padding = '5px';

          const controlWrapper = document.createElement('div');
          controlDiv.appendChild(controlWrapper);

          const zoomInButton = document.createElement('div');
          zoomInButton.className = "custom-zoom-in";
          controlWrapper.appendChild(zoomInButton);

          const zoomOutButton = document.createElement('div');
          zoomOutButton.className = "custom-zoom-out";
          controlWrapper.appendChild(zoomOutButton);

          google.maps.event.addDomListener(zoomInButton, 'click', function () {
            single_map.setZoom(single_map.getZoom() + 1);
          });

          google.maps.event.addDomListener(zoomOutButton, 'click', function () {
            single_map.setZoom(single_map.getZoom() - 1);
          });
        }

        // Marker
        const singleMapIco = "<i class='im im-icon-Hamburger'></i>";

        new CustomMarker(
          myLatlng,
          single_map,
          {
            marker_id: '1'
          },
          singleMapIco
        );
      },
    },
    computed: {
      fullAddress() {
        return getFullAddress(this.address);
      },
      ...mapState({
        donationHistory: state => state.donationHistory.allDonations,
        donationHistoryLoaded: state => state.donationHistory.donationHistoryLoaded,
      }),
      allCampaigns() {
        return this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);
      },
      styleBg() {
        if (this.charityInfo) {
          return {
            'background-image': `url('${this.charityInfo.cover_photo}')`,
          };
        }
        return {};
      },
      donationSummary() {
        const result = {
          custom: 0,
          recurring: 0,
          roundup: 0,
        };

        for (let i = 0; i < this.donationHistory.length; i++) {
          switch (this.donationHistory[i].donation_type) {
            case 'custom':
            case 'recurring':
            case 'roundup':
              result[this.donationHistory[i].donation_type] += this.donationHistory[i].amount * 1;
              break;
          }
        }

        return result;
      },
      donationsShow() {
        return this.donationHistory.slice(0, this.donationsLimit);
      },
    },
    data() {
      return {
        editDonationId: null,
        employment: null,
        address: null,
        showMap: true,
        donationsLimit: 4,
      };
    },
  }
</script>
