// import $ from 'jquery'

export default {
  methods: {
    // initBlogCarousel() {
    //   $('.blog-carousel').slick({
    //     infinite: false,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     dots: false,
    //     arrows: true,
    //     responsive: [
    //       {
    //         breakpoint: 1365,
    //         settings: {
    //           slidesToShow: 3,
    //           dots: true,
    //           arrows: false
    //         }
    //       },
    //       {
    //         breakpoint: 992,
    //         settings: {
    //           slidesToShow: 2,
    //           dots: true,
    //           arrows: false
    //         }
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 1,
    //           dots: true,
    //           arrows: false
    //         }
    //       }
    //     ]
    //   });
    // },
    goalPercent(campaign) {
      if (campaign.progress && campaign.goal) {
        return '<b>' + Math.round(parseFloat(campaign.progress.donated / 100) / parseFloat(campaign.goal / 100) * 100) + '%</b> of goal';
      }
      return '';
    },
  },
  computed: {
    styleBanner() {
      let res = {};
      if (this.charityInfo && this.charityInfo.cover_photo) {
        res['background-image'] = `url('${this.charityInfo.cover_photo}')`;
      }
      return res;
    },
  },
}