<template>
  <div class="receipt-wrap">
    <!-- Print Button -->
    <div class="print-button-container">
      <a href="javascript:window.print()" class="print-button">Print this Receipt</a>
      <div><a href="#" v-on:click.prevent="goBack"
              class="print-back-link text-primary">{{$route.params.id ? 'Back' : 'Back to Donation Page'}}</a></div>
    </div>

    <!-- Invoice -->
    <div id="invoice">

      <!-- Header -->
      <div class="row">
        <div class="col-xl-6">
          <div v-if="charityInfo" id="logo"><img :src="charityInfo.logo_transparent"  alt=""></div>
        </div>

        <div class="col-xl-6">

          <p id="details">
            <!--<strong>Receipt:</strong> #00291 <br>-->
            <strong>Date:</strong> {{donationInfo.date | dateFormat("MM/DD/YY")}} <br>
          </p>
        </div>
      </div>

      <!-- Client & Supplier -->
      <div class="row">
        <div class="col-xl-12">
          <h2 style="margin-bottom: 10px">Donation Receipt</h2>
          <p style="padding-bottom: 0">Thanks for your donation to <strong>{{donationInfo.campaign_name}}</strong></p>

          <div v-if="donationInfo.honor">
            <p style="margin-top: 20px;margin-bottom: -40px;">In {{donationInfo.honor.type === 'memory' ? 'Memory' : 'Honor'}} of <strong>{{donationInfo.honor.name}}</strong>
            </p>
            <p style="font-style: italic;">"{{donationInfo.honor.message}}"<br>
              - {{donationInfo.honor.from}}
            </p>
          </div>
        </div>

        <div class="col-xl-6">
          <strong v-if="charityInfo" class="margin-bottom-5">{{charityInfo.charity_name}}</strong>
          <p v-if="charityInfo && charityInfo.address">
            {{charityInfo.address.line_1}} {{charityInfo.address.line_2}}<br>
            {{charityInfo.address.city}}<span v-if="charityInfo.address.city && charityInfo.address.state">,</span>
            {{charityInfo.address.state}} {{charityInfo.address.zip}}
          </p>
          <p v-else></p>
        </div>

        <div class="col-xl-6">

          <div v-if="isLogin && donorDetail">
            <strong class="margin-bottom-5">{{donorDetail.first_name}} {{donorDetail.last_name}}</strong>
            <div>{{donorDetail.email}}</div>
          </div>
          <div v-if="!isLogin && donationInfo" class="margin-bottom-5">
            <strong>{{donationInfo.first_name}} {{donationInfo.last_name}}</strong>
            <div v-if="donationInfo.email">{{donationInfo.email}}</div>
          </div>

          <p v-if="donor_address">
            {{donor_address.street_number}} {{donor_address.route}}<br>
            <span style="display: block" v-if="donor_address.premise">{{donor_address.premise}}</span>
            {{donor_address.locality}}, {{donor_address.administrative_area_level_1}} {{donor_address.postal_code}}<br>
          </p>
        </div>
      </div>
      <!-- Invoice -->
      <div v-if="donationInfo" class="row">
        <div class="col-xl-12">
          <table class="margin-top-20">
            <tr>
              <th>Donation Type</th>
              <th style="width: 20%">Amount</th>
              <th style="width: 20%">Fees Covered</th>
              <th style="width: 20%">Total</th>
            </tr>
            <tr>
              <td>{{donationInfo.type.replace('custom', 'One-Time').replace('recurring', 'Monthly').replace('roundup',
                'Round-Up')}} Donation
              </td>
              <td>${{donationInfo.amount | formatMoney(2)}}</td>
              <td>${{donationInfo.processingFees | formatMoney(2)}}</td>
              <td>${{(donationInfo.amount + donationInfo.processingFees) | formatMoney(2)}}</td>
            </tr>
          </table>
        </div>

        <div class="col-xl-6">
          <table id="totals">
            <tr>
              <th>Total Donation Amount</th>
              <th><span>${{(donationInfo.amount + donationInfo.processingFees) | formatMoney(2)}}</span></th>
            </tr>
          </table>
        </div>
      </div>

      <!-- Footer -->
      <div class="row">
        <div class="col-xl-12">
          <ul id="footer">
            <!--<li><span>www.CMNHospitals.com</span></li>-->
            <li>⚡ Powered by <strong>Harness</strong></li>
            <li v-if="charityInfo">{{charityInfo.charity_name}}</li>
            <li v-if="charityInfo && charityInfo.ein">
              <b>EIN:</b> {{charityInfo.ein.replace(/(\d{2})-?(\d+)/, '$1-$2')}}
            </li>
          </ul>
          <p>No goods or services were provided in exchange for your contribution</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {userAccount} from '../../utils/api'
  import DonationTypes from '../../store/modules/donation-types'
  import {mapState, mapActions} from 'vuex'

  const moment = require('moment');

  export default {
    name: 'CustomDonation',
    props: ['type', 'isLogin', 'charityInfo', 'donorDetail'],
    mounted() {

      if (this.$route.params.id) {
        this.updateDonations();
      } else {
        if (!this.donationInfo.isDone) {
          this.$router.push({name: 'Donate'});
        }
      }
      if (this.isLogin) {
        userAccount.getAddress()
          .then(res => this.donor_address = res)
          .catch(() => this.donor_address = false);
      }
    },
    methods: {
      ...mapActions('donationHistory', [
        'updateDonations',
      ]),
      goBack() {
        this.$router.push({name: 'Donate'});
      }
    },
    computed: {
      allCampaigns() {
        return this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);
      },

      ...mapState({
        donationHistory: state => state.donationHistory.allDonations,
        donationHistoryLoaded: state => state.donationHistory.donationHistoryLoaded,
      }),
      ...mapState({
        donationInfo(state) {
          if (this.$route.params.id) {
            const info = this.donationHistory.reduce((res, donation) => donation.charge_id === this.$route.params.id ? donation : res, null);

            if (!info) {
              return false;
            }

            return {
              amount: (info.amount - info.covered_fee) / 100,
              processingFees: info.covered_fee / 100,
              first_name: '',
              last_name: '',
              email: '',
              honor: info.donation_metadata && info.donation_metadata.honor || null,
              isDone: true,
              type: info.donation_type,
              date: moment(info.created_at),
              campaign_name: info.campaign_id ? info.campaign_name : 'Area of Greatest Need',
            };
          }

          const selectedCmp = this.allCampaigns.reduce((res, cmp) => cmp.id == state.donation.campaign_id ? cmp : res, null);


          return {
            amount: state.donation.amount,
            processingFees: state.donation.is_cover_fee ? (state.donation.processingFees / 100) : 0,
            first_name: state.donation.first_name,
            last_name: state.donation.last_name,
            email: state.donation.email,
            honor: state.donation.completedDonation && state.donation.completedDonation.honorInfo,
            isDone: state.donation.isDone,
            type: state.donation.type === DonationTypes.MONTHLY ? 'recurring' : 'custom',
            campaign_name: selectedCmp ? selectedCmp.name : 'Area of Greatest Need',
            date: moment()
          };
        },
      }),
    },
    data() {
      return {
        donor_address: false,
      };
    }
  }
</script>
