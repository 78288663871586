<template>
  <div class="container margin-top-30">
    <div class="row">
      <div class="col-xl-5 offset-xl-3">

        <div class="login-register-page">

          <div>
            <div class="welcome-text">
              <h3>Reset Password</h3>

              <span>Enter your new password below</span>

            </div>

            <form method="post" @submit.prevent="submitEmail">
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
                <input type="password"
                       class="input-text with-border"
                       name="password"
                       placeholder="Password"
                       v-model="password"
                       required
                />
              </div>
              <div class="input-with-icon-left">
                <i><font-awesome-icon :icon="['far', 'envelope']" /></i>
                <input type="password"
                       class="input-text with-border"
                       name="confirmPassword"
                       placeholder="Confirm Password"
                       v-model="confirmPassword"
                       required
                />
              </div>

              <slide-up-down class="slide-up-down" :class="{'is-active': sentIsError}" :active="sentIsError">
                <div class="notification error">
                  <p>{{sentError}}</p>
                  <a href="#" v-on:click.prevent="sentIsError = false" class="close"></a>
                </div>
              </slide-up-down>

              <button class="button full-width button-sliding-icon ripple-effect margin-top-10"
                      type="submit"
              >Reset Password <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {newPassword} from '../../utils/api'

  import SlideUpDown from 'vue-slide-up-down'

  export default {
    name: 'ForgotPassword',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    methods: {
      submitEmail() {
        if(this.password !== this.confirmPassword){
          alert('Passwords do not match');
          return false
        }
        const token = this.$route.query.token;
        newPassword(token, this.password)
          .then(() => {
            const tokenParts = token.split(".");
            const harnessID = JSON.parse(window.atob(tokenParts[1]));
            window.localStorage.harness_id = harnessID.harness_id;
            this.$router.push({name: 'Donate'});
          })
          .catch(err => {
            this.sentError = (err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.')
              .replace('Invalid JWT', 'Invalid Token');
          });
      },
    },
    components: {
      SlideUpDown,
    },
    watch: {
      'email': function () {
        this.sentIsError = false;
      },
    },
    data() {
      return {
        password: '',
        confirmPassword: '',
        sentError: '',
        sentIsError: false,

      };
    },
  }
</script>
