<template>
  <div class="count-round-ups">
    <!-- Intro Banner
    ================================================== -->
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="intro-banner">
      <div class="container">

        <!-- Intro Headline -->
        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h3>
                <strong style="font-size:50px;">Round-Ups</strong>
                <br>
                <p v-if="selectedAccount && campaignsLastUpdate!==null">Donating to
                  <b>{{ selectedAccount.campaignId | campaignName(allCampaignsWithDefault) }}</b>
                </p>
              </h3>
            </div>
          </div>
        </div>
        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-6">
            <div class="intro-banner-search-form margin-top-10">
              <!-- Search Field -->
              <div v-if="isAccountsLoaded" class="intro-search-field">
                <!--<label for="autocomplete-input" class="field-title ripple-effect">Credit Card</label>-->
                <select class="default"
                        ref="selectAccount"
                        id="selectAccountId"
                        v-model="activeAccountId"
                        :title="activeAccounts.length?'Add a Card':'No Linked Cards'"
                        data-live-search="true">
                  <option v-for="account in activeAccounts"
                          :key="account.accountId"
                          :data-subtext="transactionsTotal(account)"
                          :value="account.accountId"
                          :selected="activeAccountId===account.accountId"
                  >
                    <font-awesome-icon class="margin-right-3"
                                       :icon="['fab', getFaName(allCards.get(account.fundingSourceId).metadata.brand)]"/>
                    {{ allCards.get(account.fundingSourceId).metadata.brand }}
                    {{ allCards.get(account.fundingSourceId).metadata.last4 }}
                  </option>
                  <option :value="null">
                    <font-awesome-icon class="margin-right-4" :icon="['far', 'plus-circle']"/>
                    Add a Card
                  </option>
                </select>

              </div>
              <!-- Button -->
              <div v-if="isAccountsLoaded" class="intro-search-button">

                <button v-if="activeAccountId" class="button ripple-effect"
                        v-on:click.prevent="$refs.settingsModal.open(); removeConfirm = false;"
                >Settings
                </button>
                <button v-else
                        v-on:click.prevent="$router.push({name: 'Donate', query: {'selected-method': DonationTypes.ROUNDUP}})"
                        class="button ripple-effect">Add a Card
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Stats -->
        <div class="row">
          <div v-if="isAccountsLoaded" class="col-md-12">
            <ul class="intro-stats">
              <li>
                <strong class="counter">{{ summary.swipes }}</strong>
                <span>Card Swipes</span>
              </li>
              <li>
                <strong class="counter"><sup>$</sup>{{ summary.roundUps | formatMoney }}</strong>
                <span>Round-Ups</span>
              </li>
              <li>
                <strong class="counter"><sup>$</sup>{{ summary.monthlyCap | formatMoney }}</strong>
                <span>Monthly Cap</span>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="background-image-container"
           :style="`background-image: url('${require('../../assets/roundups_bg2.png')}')`"></div>
    </div>
    <!-- Pending Round-Ups
    ================================================== -->
    <!-- Round-Ups  -->
    <div class="section padding-top-30 padding-bottom-55">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">

            <div class="notify-box">
              <div class="switch-container">
                <label class="switch"><span class="switch-text padding-left-0">Round-Ups will be donated in
                  <b style="color:#333;font-weight:600;">{{ daysLeft }}</b></span></label>
              </div>
              <div class="sort-by">
                <span>Sort by:</span>
                <bootstrap-select v-model="orderBy"
                                  :selectNowrap="true"
                                  :options="sortOptions"
                                  trackBy="id"
                                  label="name"
                                  class="hide-tick"
                ></bootstrap-select>
              </div>
            </div>

            <!-- Round-Ups Container -->
            <div v-if="isAccountsLoaded" class="listings-container compact-list-layout margin-top-25">


              <router-link
                  v-if="selectedAccount && selectedAccount.fundingSourceId && selectedAccount.errorCode"
                  :to="{name: 'RoundupsConnectCard', query: {'fix-account': selectedAccount.accountId}}"
              >
                <div

                    class="job-listing with-apply-button">

                  <!-- Round-Ups Listing Details -->
                  <div class="job-listing-details">
                    <!-- Category -->
                    <div class="job-listing-company-logo">⛔️</div>

                    <!-- Details -->
                    <div class="job-listing-description">
                      <h5>ERROR</h5>
                      <h3>Round-Ups Account Error</h3>
                      <!-- Round-Ups Listing Footer -->
                      <div class="job-listing-footer">
                        <ul>
                          <li>Please fix this error to continue donating round-ups</li>
                        </ul>
                      </div>
                    </div>

                    <!-- Button -->
                    <span class="list-apply-button ripple-effect"><i class="margin-right-6"
                    ><font-awesome-icon :icon="['far', 'edit']"/></i>Fix Error</span>
                  </div>
                </div>
              </router-link>

              <div v-else>

                <router-link v-if="!transactionsList.length && activeAccounts.length"
                             :to="{name: 'Donate', query: {'selected-method': DonationTypes.ROUNDUP}}"
                             class="job-listing with-apply-button">

                  <!-- Round-Ups Listing Details -->
                  <div class="job-listing-details">

                    <div class="job-listing-company-logo">👋</div>

                    <!-- Details -->
                    <div class="job-listing-description">
                      <h3 class="fw-600">No Round-Ups Yet This Month</h3>
                      <h3 class="job-listing-title">It may take 1-3 days to begin showing up</h3>
                    </div>
                  </div>
                </router-link>
                <router-link v-if="!transactionsList.length && !activeAccounts.length"
                             :to="{name: 'Donate', query: {'selected-method': DonationTypes.ROUNDUP}}"
                             class="job-listing with-apply-button">

                  <!-- Round-Ups Listing Details -->
                  <div class="job-listing-details">

                    <div class="job-listing-company-logo">💳</div>

                    <!-- Details -->
                    <div class="job-listing-description">
                      <h5>No Card Linked</h5>
                      <h3 class="job-listing-title">Connect a Card to "Round-Up"</h3>
                    </div>

                    <span class="list-apply-button ripple-effect"
                    ><i class="margin-right-5"><font-awesome-icon :icon="['far', 'credit-card']"/></i>Add Card</span>
                  </div>
                </router-link>

                <!-- Round-Ups Listing -->
                <a v-for="transaction in transactionsList"
                   :key="transaction.transaction_id"
                   href="#"
                   v-on:click.prevent=""
                   class="job-listing with-apply-button">

                  <!-- Round-Ups Listing Details -->
                  <div class="job-listing-details">
                    <!-- Category -->
                    <div class="job-listing-company-logo">{{ getEmoji(transaction.category_id) }}</div>

                    <!-- Details -->
                    <div class="job-listing-description">
                      <h5>{{ transaction.charge_date | dateFormat("MMMM D, YYYY") }}</h5>
                      <h3 class="job-listing-title">{{ transaction.transaction_name }}</h3>
                      <!-- Round-Ups Listing Footer -->
                      <div v-if="transaction.charge_amount" class="job-listing-footer">
                        <ul>
                          <li>${{ transaction.charge_amount | formatMoney(2) }}</li>
                        </ul>
                      </div>
                    </div>

                    <!-- Button -->
                    <span class="list-apply-button ripple-effect"><i class="margin-right-5"
                    ><font-awesome-icon :icon="['far', 'arrow-alt-circle-up']"/></i>
                      <sup>$</sup>{{ transaction.calculated_roundup | formatMoney(2) }}</span>
                  </div>
                </a>
              </div>
            </div>
            <!-- Round-Ups Container / End -->

          </div>
        </div>
      </div>
    </div>
    <!-- Round-Ups / End -->

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="settingsModal"
                          v-on:open="onOpenSettingsModal"
                          :id="'sign-in-dialog'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs modal-overflow-visible'"
    >

      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#tab-settings" class="tab-settings">Settings</a></li>
          <li><a href="#tab-remove">Remove</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Tab -->
          <div class="popup-tab-content" id="tab-settings">

            <!-- Bidding -->
            <div class="bidding-widget">
              <!-- Headline -->
              <span class="bidding-detail">
                <strong>Monthly Cap
                  <sup><i class="help-icon"
                          v-tippy="{ placement : 'top',  arrow: true, theme: 'dark'}"
                          title="A Monthly Cap allows you to set a limit on how much you donate in a single month in Round-Ups"
                  ><font-awesome-icon :icon="['fal', 'info-circle']"/></i>
                  </sup>
                </strong>
              <br>Limit my monthly Round-Ups to:</span>

              <!-- Price Slider -->
              <div class="bidding-value">$<span>{{ maxCap }}</span></div>
              <input class="bidding-slider"
                     type="text"
                     value=""
                     data-slider-handle="custom"
                     data-slider-currency="$"
                     data-slider-min="10"
                     data-slider-max="100"
                     :data-slider-value="maxCap"
                     data-slider-step="1"
                     data-slider-tooltip="hide"/>

              <!-- Headline -->
              <span class="bidding-detail margin-top-30"><strong>Ends in $.00</strong>
                <br>When a purchase ends in $.00:
              </span>
              <!-- Fields -->
              <div class="bidding-fields">
                <div class="bidding-field">
                  <bootstrap-select v-model="rounding"
                                    class="default with-border"
                                    :options="roundingOptions"
                                    trackBy="id"
                                    label="name"
                  ></bootstrap-select>
                </div>
              </div>
              <!-- Headline -->
              <span class="bidding-detail"><strong>Choose a Campaign</strong>
                <br>Designate my Round-Ups to:
              </span>
              <!-- Fields -->
              <div class="bidding-fields">
                <div class="bidding-field">
                  <bootstrap-select
                      v-model="selectedCmapignId"
                      class="default with-border margin-bottom-0"
                      track-by="id"
                      label="name"
                      :options="allCampaignsWithDefault"
                  ></bootstrap-select>
                </div>
              </div>
            </div>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-top-5"
                    v-on:click.prevent="saveSettings"
                    :disabled="!isChanged && !isCampaignChanged"
                    :class="{ disabled: !isChanged && !isCampaignChanged}"
            >Save Changes <i>
              <font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/>
            </i></button>

          </div>

          <div class="popup-tab-content" id="tab-remove">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Are you sure?</h3>
              <p class="margin-top-5">You'll no longer be donating your "Round-Up" change</p>
              <h4><strong>Remove Round-Ups for:</strong></h4>
              <div v-if="selectedAccount && allCards.has(selectedAccount.fundingSourceId)"
                   class="remove-teammate margin-top-15">
                {{ allCards.get(selectedAccount.fundingSourceId).metadata.brand }}
                {{ allCards.get(selectedAccount.fundingSourceId).metadata.last4 }}
              </div>
            </div>

            <form id="terms">
              <div class="checkbox">
                <input id="chekcbox2"
                       type="checkbox"
                       v-model="removeConfirm"
                >
                <label for="chekcbox2"><span class="checkbox-icon"></span> Yes, I confirm this action</label>
              </div>
            </form>

            <!-- Button -->
            <button class="margin-top-15 button full-width button-sliding-icon ripple-effect"
                    :disabled="!removeConfirm || !selectedAccount || isRemoveAccountProcess"
                    :class="{ disabled: !removeConfirm || !selectedAccount || isRemoveAccountProcess }"
                    v-on:click.prevent="removeAccount(selectedAccount)"
            ><span v-if="isRemoveAccountProcess"
                   class="loading-state"><font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
              Remove Round-Ups <i>
                <font-awesome-icon :icon="['far', 'times-circle']"/>
              </i></button>

          </div>
        </div>
      </div>
    </magnific-popup-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import emojiCategories from '../../utils/categories.json';
import {fundingSource, roundupAccount} from '../../utils/api'
import {formatMoney, showNotification} from '../../utils/utils'
import projectMixin from '../Parts/project-mixin';
import cardMixin from '../Donations/card-mixin';
import campaignsMixin from '../Campaigns/campaigns-mixin';
import MagnificPopupModal from '../Parts/MagnificPopupModal'
import BootstrapSelect from '../BootstrapSelect'
import {mapActions} from 'vuex'

const moment = require('moment');

export default {
  name: 'AccountRoundUps',
  props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
  mixins: [projectMixin, cardMixin, campaignsMixin],
  components: {
    MagnificPopupModal,
    BootstrapSelect,
  },
  metaInfo() {
    return {
      title: 'Round-Ups',
    }
  },
  filters: {
    campaignName(campaignId, allCampaigns) {
      campaignId = parseInt(campaignId) || 0;
      const selectedCmp = allCampaigns.reduce((res, cmp) => parseInt(cmp.id) === campaignId ? cmp : res, null);
      return selectedCmp ? selectedCmp.name : 'Area of Greatest Need';
    }
  },
  mounted() {
    /*
    curl --header "Content-Type: application/json" \
    --request POST \
    --data '{"webhook_code":"ERROR","item_id":"oz85aPna66CLoQMJeBprSy9RrVp8kBFRa4VXD","error": {"error_type":"ITEM_ERROR","error_code":"PLANNED_MAINTENANCE","error_message":"TOO_MANY_VERIFICATION_ATTEMPTS"}}' \
    https://staging.harnessapp.com/v2.0/webhook/plaid
    */

    $(".bidding-slider").slider()
        .on('slide', (event) => {
          this.maxCap = event.value;
        })
        .on('change', (event) => {
          this.maxCap = event.value.newValue;
        });

    if (this.$route.query && 'card' in this.$route.query) {
      this.activeAccountId = this.$route.query.card;
    }

    Promise.all([fundingSource.getAllFundingSources(), roundupAccount.getAllRoundupAccounts()])
        .then(([sources, accounts]) => {
          this.allSources = sources;

          this.allAccounts = this.fixAccounts(accounts);

          let allTransactions = [];
          let ids = [];
          let transactions = {};
          this.allAccounts.map(account => {
            ids.push(account.accountId);
            allTransactions.push(roundupAccount.getPendingRoundups(account.accountId, account.account_type || false));
          });

          if (this.activeAccountId && ids.indexOf(this.activeAccountId) === -1) {
            this.activeAccountId = false;
          }

          this.upDefaultActiveAccount();

          Promise.all(allTransactions).then(res => {

            for (let i = 0; i < ids.length; i++) {
              transactions[ids[i]] = res[i];
            }
            this.transactions = transactions;
            this.isAccountsLoaded = true;
            this.$nextTick(() => {
              $(this.$refs.selectAccount).selectpicker();
              if (this.activeAccountId) {
                $(this.$refs.selectAccount).selectpicker('val', this.activeAccountId);
                this.refreshSlider();
              }
            });
          });
        });

    this.upCampaigns();
  },
  methods: {
    ...mapActions('campaigns', [
      'updateCampaigns',
    ]),
    fixAccounts(accounts){
      return accounts.map(a => ({
        ...a,
        fundingSourceId: (a.account_type === "card-linked" && this.allSources.find(s => s.id == a.fundingSourceId)) ?
            this.allSources.find(s => s.id == a.fundingSourceId).funding_source_id : a.fundingSourceId,
        accountId: a.id || a.accountId,
      }));
    },
    upCampaigns() {
      if (this.charityInfo) {
        this.updateCampaigns({charity_id: this.charityInfo.charity_id});
      }
    },
    onOpenSettingsModal() {
      this.maxCap = this.selectedAccount ? this.selectedAccount.maxCap : 0;
      this.selectedCmapignId = this.selectedAccount && this.selectedAccount.campaignId || 0;
      $('.tab-settings').click();
    },
    upDefaultActiveAccount() {
      if (!this.activeAccountId && this.activeAccounts.length) {
        this.activeAccountId = this.activeAccounts[0].accountId;
      }
    },
    removeAccount(account) {
      this.isRemoveAccountProcess = true;
      roundupAccount.deleteRoundupAccount(account)
          .then(() => {
            this.isRemoveAccountProcess = false;
            this.$refs.settingsModal.close();
            this.allAccounts = this.allAccounts.filter(a => a.accountId !== account.accountId);

            this.activeAccountId = false;
            this.upDefaultActiveAccount();

            this.$nextTick(() => {
              $(this.$refs.selectAccount).selectpicker('refresh');
            });
          })
          .catch(err => {
            this.isRemoveAccountProcess = false;
            alert(err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.');
          });
    },
    saveSettings() {

      if (this.selectedAccount) {
        roundupAccount.updateRoundupSettings(
            this.selectedAccount.accountId,
            this.maxCap,
            this.selectedAccount.multiplier,
            this.rounding,
            this.selectedCmapignId,
            this.selectedAccount.account_type
        )
            .then(() => {
              this.$refs.settingsModal.close();
              roundupAccount.getAllRoundupAccounts().then(accounts => this.allAccounts = this.fixAccounts(accounts));
              showNotification(`Settings updated.`, this.charityInfo.primary_color);
            })
      }
    },
    transactionsTotal(account) {
      let sum = 0;
      if (account.accountId in this.transactions && this.transactions[account.accountId] && this.transactions[account.accountId].length) {
        sum = this.transactions[account.accountId].reduce((res, transaction) => res + parseFloat(transaction.calculated_roundup), 0);
      }

      if (sum) {
        return '$' + (formatMoney(sum, 0, 2));
      }
      return '';
    },
    getEmoji(categoryId) {
      if (categoryId in emojiCategories) {
        return String.fromCodePoint('0x' + emojiCategories[categoryId]['code'].replace('U+', ''));
      }
      return String.fromCodePoint('0x1F4C3');
    },
    refreshSlider() {
      this.maxCap = this.selectedAccount ? this.selectedAccount.maxCap : 0;
      this.rounding = (this.selectedAccount ? this.selectedAccount.rounding : 0) + '';
      this.$nextTick(() => {
        $(".bidding-slider").slider('setValue', this.maxCap);
      });
    }
  },
  computed: {
    transactionsList() {
      if (this.activeAccountId in this.transactions && this.transactions[this.activeAccountId] && this.transactions[this.activeAccountId].length) {
        let transactions = [...this.transactions[this.activeAccountId]];
        switch (this.orderBy) {
          case 'alphabetical':
            transactions.sort((a, b) => a.transaction_name.localeCompare(b.transaction_name));
            break;
          case 'category':
            transactions.sort((a, b) => a.category_name.localeCompare(b.category_name));
            break;
          default://by date
            transactions.sort((a, b) => moment(b.charge_date) - moment(a.charge_date));
            break;
        }

        return transactions;
      }
      return [];
    },
    summary() {
      let res = {
        swipes: 0,
        roundUps: 0,
        monthlyCap: this.selectedAccount ? this.selectedAccount.maxCap : 0,
      };
      if (this.activeAccountId && this.transactions && this.transactions[this.activeAccountId]) {
        res.swipes = this.transactions[this.activeAccountId].length;
        res.roundUps = this.transactions[this.activeAccountId].reduce((res, t) => res + parseFloat(t.calculated_roundup), 0);
      }

      return res;
    },
    daysLeft() {
      return moment().to(moment().endOf('month'), true);
    },
    allCards() {
      let cards = new Map();
      this.allSources.map(source => cards.set(source.funding_source_id, source));
      return cards;
    },
    selectedAccount() {
      return this.allAccounts.reduce((res, a) => a.accountId === this.activeAccountId ? a : res, null);
    },
    activeAccounts() {
      return this.allAccounts.filter(account => {
        if (account.fundingSourceId && this.allCards.has(account.fundingSourceId)) {
          return true;
        }
        if (account.account_type === "card-linked" && this.allSources.find(s => s.id == account.fundingSourceId)) {
          return true;
        }
        return false;
      });
    },
    isChanged() {
      return this.selectedAccount &&
          (this.selectedAccount.maxCap !== this.maxCap || this.selectedAccount.rounding !== this.rounding);
    },
    isCampaignChanged() {
      return this.selectedCmapignId !== (this.selectedAccount && this.selectedAccount.campaignId || 0);
    }
  },
  watch: {
    'charityInfo'() {
      this.upCampaigns();
    },
    'activeAccountId'() {
      if (this.activeAccountId === null) {
        this.$router.push({name: 'Donate', query: {'selected-method': this.DonationTypes.ROUNDUP}}).catch(() => {
        });
      } else if (!this.activeAccountId) {
        this.$router.push({name: 'AccountRoundUps'}).catch(() => {
        });
      } else {
        this.$router.replace({query: Object.assign({}, this.$route.query, {card: this.activeAccountId})}).catch(() => {
        });
        this.refreshSlider();
      }
    },
  },
  data() {
    return {
      sortOptions: [
        {id: 'date', name: 'Date'},
        {id: 'alphabetical', name: 'Alphabetical'},
        {id: 'category', name: 'Category'},
      ],
      roundingOptions: [
        {id: '0', name: 'Give $0.00'},
        {id: '100', name: 'Give $1.00'}
      ],
      allAccounts: [],
      isAccountsLoaded: false,
      allSources: [],
      transactions: {},
      activeAccountId: null,
      orderBy: 'date',
      maxCap: 40,
      rounding: '100',
      removeConfirm: false,
      isRemoveAccountProcess: false,
      selectedCmapignId: 0
    };
  },
}
</script>
