<template>
  <div
      class="header-notifications hn-dd"
      v-bind:class="{ 'active': isDropdownActive }"
      v-on-clickaway="away"
  >
    <div
        class="header-notifications-trigger"
        @click.prevent="isDropdownActive=!isDropdownActive"
    >
      <slot name="trigger"></slot>
    </div>
    <div class="header-notifications-dropdown">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
  import {mixin as clickaway} from 'vue-clickaway';

  export default {
    props: ['id'],
    mixins: [clickaway],
    created() {
      this.$root.$on('dropdown:hide', () => this.isDropdownActive = false);
    },
    data() {
      return {
        isDropdownActive: false,
      }
    },
    methods: {
      away() {
        this.isDropdownActive = false;
      }
    }
  };
</script>
