<template>
  <div>
    <inner-layout :charity-info="charityInfo">
      <EmploymentForm
          v-if="employment"
          :charity-info="charityInfo"
          :employmentInitial="employment"
          v-on:saved="$router.push({name: 'Donate'})"
      ></EmploymentForm>
      <!-- Spacer -->
      <div class="margin-top-30"></div>
      <!-- Spacer / End-->
    </inner-layout>
  </div>
</template>

<script>
  import {userAccount} from '../../utils/api'
  import EmploymentForm from './employment-form'
  import InnerLayout from '../Parts/inner-layout.vue'

  export default {
    name: 'Employment',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    metaInfo() {
      return {
        title: 'Employer Matching',
      }
    },
    components: {
      InnerLayout,
      EmploymentForm
    },
    mounted() {
      this.updateEmployment();
    },
    methods: {
      updateEmployment() {
        userAccount.getEmployment()
          .then(employment => {
            employment.check_matching = parseInt(employment.check_matching);
            this.employment = employment;
          })
          .catch(() => this.employment = {employer_name: '', occupation: '', check_matching: 1});
      },
    },
    data() {
      return {
        employment: false,
      };
    },
  }
</script>
