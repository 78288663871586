<template>
  <inner-layout :charity-info="charityInfo"
                :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
  >
    <div class="sign-in-form">
      <div class="popup-tabs-container">
        <div class="popup-tab-content">
          <div class="welcome-text">
            <h3>Login to Account</h3>
            <span>Don't have an account? <router-link :to="{name: 'Register'}" class="register-tab">Sign Up!</router-link></span>
          </div>

          <login-form :charityInfo="charityInfo"
                      v-on:update-donor-details="$emit('update-donor-details')"
          ></login-form>
        </div>
      </div>
    </div>
  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import LoginForm from '../Parts/login-form.vue'

  export default {
    name: 'LoginPage',
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
    },
    components: {
      InnerLayout,
      LoginForm,
    },
    watch: {
      'isLogin'() {
        if(this.isLogin) {
          this.$router.push({name: 'Account'});
        }
      },
    }
  }
</script>