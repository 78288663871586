<template>
  <inner-layout :charity-info="charityInfo"
                :cover-photo="(selectedCampaign && selectedCampaign.id && selectedCampaign.image)?selectedCampaign.image:''"
  >
    <register-screen
       v-on:update-donor-details="$emit('update-donor-details')"
       v-on:open-login-only-modal="$router.push({name: 'Login'})"
       :allow_guest_checkout="false"
       :harness_fields="{phone_number: {enabled: true, required: false}, address: {enabled: false}}"
       :isLogin="isLogin"
       :charityInfo="charityInfo"
       :registerTitle="'Create an Account'"
    >
      <div v-if="$route.meta.texts" class="welcome-text">
        <h3 style="font-size: 26px;">Text Updates</h3>
        <span>Want to receive text updates?
        <a href="https://donor-support.drift.help" target="_blank" class="popup-with-zoom-anim">Learn More</a>
      </span>
      </div>
    </register-screen>
  </inner-layout>
</template>

<script>
  import InnerLayout from '../Parts/inner-layout.vue'
  import RegisterScreen from '../Donations/register.vue'

  export default {
    name: 'LoginPage',
    metaInfo() {
      return {
        title: this.$route.meta.texts ? 'Text Updates' : 'Create an Account',//
      }
    },
    props: {
      isLogin: null,
      charityInfo: null,
      donorDetail: null,
      donationConfig: null,
      donationInfo: null,
      selectedCampaign: null,
    },
    methods: {},
    components: {
      InnerLayout,
      RegisterScreen,
    },
    watch: {
      'isLogin'() {
        if (this.isLogin) {
          this.$router.push({name: 'Account'});
        }
      },
    }
  }
</script>