<template>
  <div class="monthly-donations-wrap">
    <!-- Intro Banner
    ================================================== -->
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="single-page-header" style="padding: 50px 0; min-height:0">
      <div class="container">

        <!-- Intro Headline -->
        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h3>
                <strong style="font-size: 50px;">Monthly Donations</strong>
                <br>
                <p>Thank you for supporting our organization on a monthly basis!</p>
              </h3>
            </div>
          </div>
        </div>
        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-8">
            <div class="intro-banner-search-form margin-top-10">

              <!-- Search Field -->
              <div class="intro-search-field with-autocomplete" style="max-width: 150px;">
                <div class="input-with-icon">
                  <input type="number"
                         placeholder="50"
                         ref="donateAmount"
                         v-model="donateAmount"
                  >
                  <i><font-awesome-icon :icon="['far', 'dollar-sign']" /></i>
                </div>
              </div>

              <!-- Search Field -->
              <div class="intro-search-field">
                <bootstrap-select
                        class="default"
                        v-model="donateCampaign"
                        title="Choose a Campaign"
                        track-by="id"
                        label="name"
                        :options="allCampaignsWithDefault"
                        :subtext-callback="goalPercent"
                ></bootstrap-select>
              </div>

              <!-- Button -->
              <div class="intro-search-button">
                <button class="button ripple-effect"
                        v-on:click.prevent="goToDonationPage"
                > Give Monthly
                </button>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="background-image-container"
           :style="`background-image: url('${require('../../assets/header_calendar_shorter.png')}')`"></div>
    </div>

    <!-- Page Content
    ================================================== -->
    <div class="container margin-top-35">
      <div v-if="donationsLoaded">
        <div v-if="donations && donations.length" class="row">

          <div class="col-xl-3 col-lg-4 hide-on-mobile">
            <div class="sidebar-container">

              <!-- Campaign Filter -->
              <div v-if="activeCampaigns && activeCampaigns.length" class="sidebar-widget">
                <h3>Filter by Campaign</h3>
                <bootstrap-select
                        class="default"
                        v-model="filterCampaign"
                        title="All Campaigns"
                        data-live-search="true"
                        track-by="id"
                        label="name"
                        :options="activeCampaigns"
                        :subtext-callback="goalPercent"
                ></bootstrap-select>
              </div>

              <div class="clearfix"></div>

              <!-- Filtering -->
              <div v-if="activeCards && activeCards.length" class="sidebar-widget">
                <h3>Filter by Card</h3>

                <div class="tags-container">
                  <div v-for="card in activeCards"
                       :key="card.funding_source_id"
                       class="tag">
                    <input type="checkbox"
                           v-model="filterCard"
                           :value="card.funding_source_id"
                           :id="`tag_${card.funding_source_id}`"
                    />
                    <label v-if="card.metadata"
                           :for="`tag_${card.funding_source_id}`"
                    ><i><font-awesome-icon :icon="['far', 'check']"/></i>
                      {{card.metadata.brand}} {{card.metadata.last4}}</label>
                  </div>
                </div>

                <div class="clearfix"></div>

                <router-link :to="{name: 'PaymentMethods'}"
                             class="button full-width ripple-effect button-sliding-icon margin-top-5"
                >Manage Payments <i><font-awesome-icon :icon="['far', 'credit-card']" /></i></router-link>
              </div>

              <div class="clearfix"></div>
            </div>
          </div>

          <div class="col-xl-9 col-lg-8 content-left-offset">


            <h3 class="page-title">Monthly Donations</h3>

            <div class="notify-box margin-top-15">
              <div class="switch-container">
                <label class="switch"><span class="switch-text padding-left-10">
                  Displaying <a href="#">{{currentPage.length === donations.length?'All':currentPage.length}}</a>
                  of {{donations.length}} Monthly Donation{{donations.length === 1 ? '' : 's'}}
                </span>
                </label>
              </div>

              <div class="sort-by">
                <span>Sort by:</span>
                <bootstrap-select
                    v-model="sortBy"
                    class="hide-tick"
                    label="name"
                    track-by="id"
                    :selectNowrap="true"
                    :options="[
                        {id: 'amount', name: 'Amount'},
                        {id: 'campaign-name', name: 'Campaign Name'},
                        {id: 'newest', name: 'Newest'},
                        {id: 'oldest', name: 'Oldest'},
                    ]"
                ></bootstrap-select>
              </div>
            </div>

            <!-- Monthly Donations Container -->
            <div class="tasks-list-container margin-top-25">

              <!-- Monthly Donation Listing -->
              <a href="#"
                 v-on:click.prevent=""
                 v-for="item in currentPage"
                 :key="item.subscriptionId"
                 class="job-listing">

                <!-- Monthly Donation Listing Details -->
                <div class="job-listing-details">
                  <!-- Logo -->
                  <div class="job-listing-company-logo">
                    <img v-if="item.campaignInfo && item.campaignInfo.image" :src="item.campaignInfo.image" alt="">
                  </div>

                  <!-- Details -->
                  <div class="job-listing-description">
                    <h4 class="job-listing-company">${{parseFloat(item.amount) / 100 |
                      formatMoney}} Monthly</h4>
                    <h3 v-if="item.campaignInfo" class="job-listing-title">{{item.campaignInfo.name}}</h3>
                    <!--p class="job-listing-text">This is where the donation note can go for this monthly donation! Or we
                      can have it show a message from the organization thanking the donor!</p-->
                  </div>
                  <Dropdown>
                    <span slot="trigger" class="bookmark-icon"><font-awesome-icon :icon="['far', 'ellipsis-v']"/></span>
                    <div slot="content" class="dropdown-content">
                      <ul class="user-menu-small-nav">
                        <li v-if="item.campaignInfo">
                          <router-link :to="{name: 'Campaign', params: {'campaignId': item.campaignInfo.id}}"
                          ><i><font-awesome-icon :icon="['far', 'chart-pie-alt']"/></i> View Campaign
                          </router-link>
                        </li>

                        <li><a href="#"
                               v-on:click.prevent="openEditDonationModal(item)"
                        ><i><font-awesome-icon :icon="['far', 'edit']"/></i> Edit Donation</a></li>

                        <li><a href="#"
                               v-on:click.prevent="donationToDelete = item;$refs.removeDonationModal.open()"
                               class="remove-item"><i><font-awesome-icon :icon="['far', 'trash-alt']"/></i> Cancel Donation</a>
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                </div>

                <!-- Monthly Donation Listing Footer -->
                <div class="job-listing-footer">
                  <ul>
                    <li v-if="item.cardInfo"><i><font-awesome-icon :icon="['far', 'credit-card']" /></i>
                      {{item.cardInfo.metadata.brand}} {{item.cardInfo.metadata.last4}}
                    </li>
                    <li><i><font-awesome-icon :icon="['far', 'repeat']" /></i> Monthly</li>
                    <li title="Date Started"
                        v-tippy="{ placement : 'bottom'}"
                    ><i><font-awesome-icon :icon="['far', 'calendar']"/></i> {{item.createdAt | dateFormat("MMM D, YYYY")}}
                    </li>
                    <li :title="item.nextPaymentDate |  dateFormat('MMMM D, YYYY')"
                        v-tippy="{ placement : 'bottom'}"
                    ><i><font-awesome-icon :icon="['far', 'clock']" /></i> Donates
                      {{item.nextPaymentDate | dateFromNow}}
                    </li>
                  </ul>
                </div>
              </a>

              <!-- Pagination -->
              <div class="clearfix"></div>
              <div class="row" v-if="donations && donations.length && totalPages>1">
                <div class="col-md-12">
                  <!-- Pagination -->
                  <div class="pagination-container margin-top-30 margin-bottom-60">
                    <nav class="pagination">
                      <paginate
                          v-model="page"
                          :page-count="totalPages"
                          :click-handler="onPage"
                          prev-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-left">
                         <path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path>
                         </svg></i>'
                          next-text='<i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" class="svg-inline--fa fa-chevron-right">
                         <path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
                         </svg></i>'
                          :prev-class="'pagination-arrow'"
                          :next-class="'pagination-arrow'"
                          :page-link-class="'ripple-effect'"
                          :prev-link-class="'ripple-effect'"
                          :next-link-class="'ripple-effect'"
                      >
                      </paginate>
                    </nav>
                  </div>
                </div>
              </div>
              <!-- Pagination / End -->

            </div>
            <!-- Tasks Container / End -->
          </div>

        </div>
        <div v-else>
          <p class="margin-bottom-30">No Monthly Donations</p>
        </div>
      </div>
    </div>
    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="removeDonationModal"
                          :id="'small-dialog-1'"
                          v-on:open="$root.$emit('dropdown:hide');isDeleteConfirm = false"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a class="color-red" href="#tab1">Remove Donation</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Tab -->
          <div class="popup-tab-content" id="tab1">

            <!-- Welcome Text -->
            <div class="welcome-text margin-bottom-15">
              <h3>Are you sure?</h3>
              <p class="margin-top-5">This monthly donation will be deleted and no future donations will be made for
                this recurring gift</p>
              <h4><strong>Please confirm that you'd like to cancel this donation:</strong></h4>

              <!--<div class="remove-teammate margin-top-15">
                Mastercard 5566
              </div>-->

            </div>

            <form>
              <div class="checkbox checkbox-red">
                <input id="chekcbox23" type="checkbox"
                       v-model="isDeleteConfirm"
                >
                <label for="chekcbox23"><span class="checkbox-icon"></span> Yes, please cancel this donation</label>
              </div>
            </form>

            <!-- Button -->
            <button class="margin-top-15 button full-width button-sliding-icon ripple-effect bg-color-red"
                    :class="{ disabled: !isDeleteConfirm || isDeletingDonation }"
                    :disabled="!isDeleteConfirm  || isDeletingDonation"
                    v-on:click.prevent="deleteDonation(donationToDelete)"
            ><span v-if="isDeletingDonation" class="loading-state">
              <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>Remove Donation <i
            class="margin-left-3"><font-awesome-icon :icon="['far', 'trash-alt']" /></i></button>

          </div>

        </div>
      </div>
    </magnific-popup-modal>

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="editDonationModal"
                          v-on:close="isEditModal = false"
                          v-on:open="isEditModal = true"
                          :id="'small-dialog'"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#tab">Edit Donation</a></li>
        </ul>

        <div class="popup-tabs-container" style="overflow: visible">

          <!-- Tab -->
          <div class="popup-tab-content" id="tab">

            <!-- Welcome Text -->
            <div class="welcome-text margin-bottom-15">
              <h3 v-if="donationToEdit">${{(donationToEdit.amount - donationToEdit.feesCovered)/100 | formatMoney}}
                Monthly Donation</h3>
              <p class="margin-top-5">Edit the settings for this monthly donation</p>
            </div>

            <!-- Settings Row -->
            <div class="bidding-widget">
              <!-- Headline -->
              <span class="bidding-detail margin-top-30"><strong>Campaign Designation:</strong></span>
              <!-- Fields -->
              <div class="bidding-fields">
                <div class="bidding-field">
                  <bootstrap-select
                          class="default with-border"
                          v-model="edit.campaign_id"
                          track-by="id"
                          label="name"
                          :subtext-callback="goalPercent"
                          :options="allCampaignsWithDefault"
                  >
                  </bootstrap-select>
                </div>
              </div>
            </div>

            <!-- Settings Row -->
            <div class="bidding-widget">
              <!-- Headline -->
              <span class="bidding-detail margin-top-20"><strong>Payment Source:</strong></span>
              <!-- Fields -->
              <div class="bidding-fields">
                <div v-if="edit.funding_source_id !== 'new'" key="select-a-card" class="bidding-field">
                  <bootstrap-select
                      v-model="edit.funding_source_id"
                      class="default with-border"
                      track-by="id"
                      label="name"
                      :options="cardsOptions"
                  ></bootstrap-select>
                </div>
                <div v-else class="bidding-field margin-bottom-5" key="add-a-card">
                  <div v-init-stripe class="margin-bottom-15"></div>

                  <slide-up-down class="slide-up-down" :class="{'is-active': !!cardIsError}" :active="!!cardIsError">
                    <div class="notification error margin-bottom-15">
                      <p>{{cardAddError}}</p>
                      <a href="#" v-on:click.prevent="cardIsError = false" class="close"></a>
                    </div>
                  </slide-up-down>
                </div>

              </div>
            </div>

            <!-- Settings Row -->
            <div v-if="donationToEdit" class="bidding-widget">
              <!-- Headline -->
              <span class="bidding-detail margin-top-20"><strong>Next Donation Date:</strong>
            <br>Your next donation will occur on <strong>{{donationToEdit.nextPaymentDate |  dateFormat('MMMM D, YYYY')}}</strong></span>
            </div>

            <!-- Button -->
            <button class="button full-width button-sliding-icon ripple-effect margin-bottom-10"
                    :class="{ disabled: !isEdited || isCardConnecting }"
                    :disabled="!isEdited || isCardConnecting"
                    v-on:click.prevent="updateDonation"
            ><span v-if="isCardConnecting" class="loading-state">
                <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>Save Changes <i
                class="margin-left-3"><font-awesome-icon :icon="['far', 'save']" /></i></button>
          </div>

        </div>
      </div>
    </magnific-popup-modal>

    <vue-recaptcha v-if="isEditModal"
                   :sitekey="$root.config.google.recaptcha_key_invisible ? $root.config.google.recaptcha_key_invisible : '6Ld0WrsUAAAAAOawnDvZtuFRpPtwuV7ptlpxJJjW'"
                   class="margin-bottom-15"
                   :loadRecaptchaScript="true"
                   ref="recaptcha"
                   size="invisible"
                   @verify="onVerifyInvisible"
                   @expired="onExpired"
    ></vue-recaptcha>

  </div>
</template>

<script>
  import $ from 'jquery'
  import _ from 'underscore'

  import {fundingSource, recurringDonation} from "../../utils/api";
  import {mapActions} from 'vuex'
  import campaignsMixin from '../Campaigns/campaigns-mixin';
  import projectMixin from '../Parts/project-mixin';
  import Paginate from 'vuejs-paginate'
  import Dropdown from "../Dropdown";
  import BootstrapSelect from "../BootstrapSelect";
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import {showNotification} from "../../utils/utils";
  import VueRecaptcha from 'vue-recaptcha';
  import recaptchaMixin from '../Donations/recaptcha-mixin'
  import SlideUpDown from 'vue-slide-up-down'
  import stripeDirectiveMixin from './stripe-directive-mixin'

  const moment = require('moment');

  export default {
    name: 'MonthlyDonations',
    props: ['isLogin', 'donorDetail', 'charityInfo'],
    mixins: [campaignsMixin, projectMixin, recaptchaMixin, stripeDirectiveMixin],
    metaInfo() {
      return {
        title: 'Monthly Donations',
      }
    },
    components: {
      Paginate,
      Dropdown,
      MagnificPopupModal,
      VueRecaptcha,
      SlideUpDown,
      BootstrapSelect
    },
    mounted() {
      this.initDonations();
      this.upCampaigns();
    },
    methods: {
      onVerifyInvisible(response) {
        this.captchaKey = response;
        this.updateDonation();
      },
      async updateDonation() {
        let isError = false;
        let updates = [];
        if (this.edit.funding_source_id !== 'new') {
          this.$refs.editDonationModal.close();
        }

        if (this.edit.campaign_id !== this.donationToEdit.campaignId) {
          updates.push(recurringDonation.updateRecurringCampaign(this.donationToEdit.subscriptionId, this.edit.campaign_id));
        }
        if (this.edit.funding_source_id !== this.donationToEdit.platformFundingSourceId) {

          if (this.edit.funding_source_id === 'new') {

            if (this.captchaKey === '') {
              this.$refs.recaptcha.execute();
              return null;
            }

            this.isCardConnecting = true;
            this.cardIsError = false;
            this.cardAddError = '';

            await this.stripe.createToken(this.cardObj).then(async (result) => {
              if (result.error) {
                // Inform the user if there was an error.
                this.cardIsError = true;
                this.cardAddError = result.error.message;
                this.isCardConnecting = false;
                this.$refs.recaptcha.reset();
                this.onExpired();
              } else {
                const cardId = result.token.card.id;

                await fundingSource.addFundingSource(result.token.id, this.captchaKey)
                  .then((sourceInfo) => {
                    this.isCardConnecting = false;
                    this.$tracking.track(this.$tracking.EVENTS.ADD_PAYMENT_INFO, {
                      'content_category': result.token.card && result.token.card.brand || 'Payment Card',
                    });
                    updates.push(recurringDonation.updateRecurringFundingSource(this.donationToEdit.subscriptionId, sourceInfo.funding_source_id || cardId));
                  })
                  .catch(err => {
                    isError = true;
                    this.$refs.recaptcha.reset();
                    this.onExpired();
                    const fullError = err.responseJSON && err.responseJSON.full_error || '';
                    this.cardIsError = true;
                    this.cardAddError = err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.';
                    if (fullError) {
                      this.cardAddError = `${this.cardAddError}. ${fullError}`;
                    }
                    this.isCardConnecting = false;
                  });
              }
            });

          } else {
            updates.push(recurringDonation.updateRecurringFundingSource(this.donationToEdit.subscriptionId, this.edit.funding_source_id));
          }
        }

        if (!isError) {
          Promise.all(updates).then(() => {
            this.$refs.editDonationModal.close();
            showNotification(`Donation has been updated.`, this.charityInfo.primary_color);

            setTimeout(() => this.initDonations(), 300);
          })
            .catch(err => {
              alert(err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.');
            });
        }
      },
      openEditDonationModal(donationToEdit) {
        donationToEdit.campaignId = donationToEdit.campaignId || 0;
        this.donationToEdit = donationToEdit;
        this.$refs.editDonationModal.open();
        this.$root.$emit('dropdown:hide');

        this.edit.campaign_id = this.donationToEdit.campaignId;
        this.edit.funding_source_id = this.donationToEdit.platformFundingSourceId;
      },
      initDonations() {
        Promise.all([recurringDonation.getRecurringDonations(), fundingSource.getAllFundingSources()])
          .then(([donations, sources]) => {
            this.donations = donations;
            this.donationsLoaded = true;
            this.cards = sources;
          });
      },
      ...mapActions('campaigns', [
        'updateCampaigns',
      ]),
      deleteDonation(donationToDelete) {
        this.isDeletingDonation = true;

        recurringDonation.deleteRecurringDonation(donationToDelete.subscriptionId)
          .then(() => {
            this.isDeletingDonation = false;
            this.$refs.removeDonationModal.close();
            showNotification(`Recurring donation has been deleted`, this.charityInfo.primary_color);
            this.initDonations();
          })
          .catch(err => {
            this.isDeletingDonation = false;
            alert(err.responseJSON && err.responseJSON.error || 'An error has occurred, please try again.');
          });
      },
      goToDonationPage() {
        if (!this.donateAmount) {
          this.$refs.donateAmount.focus();
          return false;
        }

        let queryParams = {
          type: 'monthly',
          amount: this.donateAmount
        };

        if (this.donateCampaign !== null) {
          queryParams['campaign_id'] = this.donateCampaign;
        }
        this.$router.push({name: 'DonationProceed', query: queryParams});
      },
      onPage(pageNumber) {
        this.page = pageNumber;

        $('html, body').animate({scrollTop: 0}, 300);
      },
      resetPagination() {
        this.page = 1;
      },
      upCampaigns() {
        if (this.charityInfo) {
          this.updateCampaigns({charity_id: this.charityInfo.charity_id});
        }
      },
      nextPaymentDate(date) {
        const startDate = moment(date);
        const diffMonths = moment().diff(startDate, 'months') + 1;
        return startDate.add(diffMonths, 'months');
      },
    },
    computed: {
      cardsOptions() {
        return [
          ...this.cards.map(card => ({
            id: card.funding_source_id,
            name: `${card.metadata.brand} ${card.metadata.last4}`
          })),
          {id: 'new', name: 'Add a Card', 'data-icon': 'plus-circle'}
        ];
      },
      isEdited() {
        return this.donationToEdit && this.edit.funding_source_id &&
          (
            this.edit.campaign_id !== this.donationToEdit.campaignId
            || this.edit.funding_source_id !== this.donationToEdit.platformFundingSourceId
          )
      },
      allDonations() {
        let allDonations = JSON.parse(JSON.stringify(this.donations));

        allDonations = allDonations.map(item => {
          return {
            ...item,
            cardInfo: this.cards.reduce((res, card) => card.funding_source_id == item.platformFundingSourceId ? card : res, null),
            campaignInfo: this.allCampaignsWithDefault.reduce((res, cmp) => cmp.id == (item.campaignId === null ? 0 : item.campaignId) ? cmp : res, null),
            nextPaymentDate: this.nextPaymentDate(item.createdAt)
          };
        });

        return allDonations;
      },
      filteredDonations() {

        let allDonations = Array.from(this.allDonations);

        if (this.filterCampaign !== null) {
          allDonations = allDonations.filter(donation => donation.campaignInfo && donation.campaignInfo.id === this.filterCampaign);
        }

        if (this.filterCard && this.filterCard.length) {
          allDonations = allDonations.filter(donation => this.filterCard.indexOf(donation.platformFundingSourceId) !== -1);
        }

        switch (this.sortBy) {
          case 'newest':
            allDonations.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
            break;
          case 'oldest':
            allDonations.sort((a, b) => moment(a.createdAt) - moment(b.createdAt));
            break;
          case "amount":
            allDonations.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
            break;
          case "campaign-name":
            allDonations.sort((a, b) => {
              if (!a.campaignInfo || !b.campaignInfo) {
                return 0;
              }
              return a.campaignInfo.name.localeCompare(b.campaignInfo.name)
            });
            break;
        }

        return allDonations;
      },
      currentPage() {
        const start = (this.page - 1) * this.perPage;
        return this.filteredDonations.slice(start, start + this.perPage);
      },
      totalPages() {
        return Math.ceil(this.filteredDonations.length / this.perPage);
      },
      activeCards() {
        let cards = this.allDonations.map(donation => donation.cardInfo);
        cards = cards.filter(Boolean);
        cards = _.uniq(cards, (x) => x.funding_source_id);

        return cards;
      },
      activeCampaigns() {
        let campaigns = this.allDonations.map(donation => donation.campaignInfo);
        campaigns = campaigns.filter(Boolean);

        campaigns = _.uniq(campaigns, (x) => x.id);
        campaigns = _.sortBy(campaigns, (x) => x.name);

        return campaigns;
      }
    },
    watch: {
      'charityInfo'() {
        this.upCampaigns();
      },
    },
    data() {
      return {
        filterCampaign: null,
        filterCard: [],
        donations: [],
        donationsLoaded: false,
        donationToDelete: null,
        isDeleteConfirm: false,

        donationToEdit: null,
        edit: {
          campaign_id: null,
          funding_source_id: null
        },

        cards: [],
        page: 1,
        perPage: 20,

        donateAmount: 50,
        donateCampaign: null,

        sortBy: 'newest',

        isDeletingDonation: false,

        isEditModal: false,
        cardIsError: false,
        cardAddError: '',
        isCardConnecting: false,
        cardType: '',
        stripe: null,
        cardObj: null,
      };
    },
  }
</script>
