import {mapState} from 'vuex'

export default {
  methods: {
    completePercent(campaign) {
      return Math.round(parseFloat(campaign.progress.donated / 100) / parseFloat(campaign.goal / 100) * 100);
    },
    goToCampaign() {
      if (this.selectedCampaignId !== false) {
        this.$router.push({name: 'Campaign', params: {campaignId: this.selectedCampaignId}});
      }
    },
    sortCampaigns(campaigns, type){
      switch (type) {
        case "donations":
          return [...campaigns].sort((cmp1, cmp2) => {
            let val1 = (cmp1.progress && cmp1.progress.donated) ? parseInt(cmp1.progress.donated) : 0;
            let val2 = (cmp2.progress && cmp2.progress.donated) ? parseInt(cmp2.progress.donated) : 0;

            return val2 - val1;
          });
        case "completion":
          return [...campaigns].sort((cmp1, cmp2) => {
            let val1 = (cmp1.progress && cmp1.progress.donated) ? parseInt(cmp1.progress.donated) : 0;
            let val2 = (cmp2.progress && cmp2.progress.donated) ? parseInt(cmp2.progress.donated) : 0;
            if (cmp1.progress && cmp1.goal) {
              val1 = this.completePercent(cmp1) + 10000000000
            }
            if (cmp2.progress && cmp2.goal) {
              val2 = this.completePercent(cmp2) + 10000000000
            }
            return val2 - val1;
          });
        case "alphabet":
        default:
          return [...campaigns].sort((cmp1, cmp2) => {
            return cmp1.name.localeCompare(cmp2.name)
          });
      }
    }
  },
  computed: {
    allCampaignsWithDefault() {
      return this.$store.getters['campaigns/allCampaignsWithDefault'](this.charityInfo);
    },
    ...mapState({
      allCampaigns: state => state.campaigns.allCampaigns,
      campaignsLastUpdate: state => state.campaigns.lastUpdate,
    }),
    sortedCampaigns() {
      return this.sortCampaigns(this.allCampaigns, 'completion');
    },
    statistic() {
      return this.allCampaigns.reduce((res, cmp) => {
        if (cmp.progress) {
          res.donors += parseInt(cmp.progress.donors) || 0;
          res.donations += parseInt(cmp.progress.donations) || 0;
          res.donated += parseInt(cmp.progress.donated) || 0;
          res.goal += parseInt(cmp.goal) || 0;
        }
        return res;
      }, {
        donors: 0,
        donations: 0,
        donated: 0,
        goal: 0
      })
    },
  },
}