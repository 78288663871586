<template>
  <div>
    <inner-layout :charity-info="charityInfo">

      <div v-if="employment" class="login-register-page">
        <!-- Welcome Text -->
        <div class="welcome-text">
          <h3 style="font-size: 26px;">Employer Matching</h3>
          <span>Would you like us to check for matching?</span>
        </div>

        <!-- Cover Image -->
        <div class="welcome-text margin-top-10">
            <span class="company-logo">
              <img :src="require('./employer_matching4.png')"
                   alt=""
                   style="max-width:100%; margin-top: -10px;">
            </span>
        </div>

        <!-- Form -->
        <form method="post" @submit.prevent="saveEmployment">
          <div v-if="isEmployed">
            <div class="input-with-icon-left no-border">
              <i><font-awesome-icon :icon="['far', 'briefcase']" /></i>
              <input type="text"
                     class="input-text no-border"
                     placeholder="Employer"
                     v-model="employment.employer_name"
                     required/>
            </div>

            <div class="checkbox">
              <input type="checkbox"
                     id="chekcbox1"
                     v-model="employment.check_matching"
              >
              <label for="chekcbox1"><span class="checkbox-icon"></span> Yes, you may privately check if my employer offers
                <a
                    href="#"
                    v-on:click.prevent=""
                    title="We'll check with your employer to see if they offer a matching gift program for donations"
                    v-tippy="{ placement : 'top',  arrow: true}"
                >Donation Matching</a></label>
            </div>
          </div>
          <!-- Button -->
          <button class="button full-width button-sliding-icon margin-top-10"
                  v-ripple-dark
                  type="submit"
          >Save & Continue <i><font-awesome-icon :icon="['far', 'long-arrow-alt-right']" /></i></button>

        </form>
      </div>
      <!-- Spacer -->
      <div class="margin-top-30"></div>
      <!-- Spacer / End-->
    </inner-layout>
  </div>
</template>

<script>
  import Snackbar from 'node-snackbar';
  import {userAccount} from '../../utils/api'
  import InnerLayout from '../Parts/inner-layout.vue'

  export default {
    name: 'EmployerMatching',
    props: ['type', 'isLogin', 'donorDetail', 'charityInfo'],
    metaInfo() {
      return {
        title: 'Employer Matching',
      }
    },
    components: {
      InnerLayout,
    },
    mounted() {
      this.updateEmployment();
    },
    methods: {
      updateEmployment() {
        userAccount.getEmployment()
          .then(employment => {
            employment.check_matching = parseInt(employment.check_matching);
            this.employment = employment;
          })
          .catch(() => this.employment = {employer_name: '', occupation: '', check_matching: 1});
      },
      saveEmployment() {

        userAccount.saveEmployment(
          this.isEmployed ? this.employment.employer_name : '',
          this.isEmployed ? this.employment.occupation : '',
          this.isEmployed ? +this.employment.check_matching : 0
        ).then((res) => {
          Snackbar.show({
            text: res.message,
            pos: 'top-center',
            showAction: false,
            actionText: "Dismiss",
            duration: 3000,
            textColor: '#fff',
            backgroundColor: this.charityInfo.primary_color
          });
//          this.updateEmployment();
          this.$router.push({name: 'Donate'});
        })
          .catch(() => alert('An error has occurred, please try again.'))
      },
    },
    computed: {},
    data() {
      return {
        employment: false,
        isEmployed: true
      };
    },
  }
</script>
