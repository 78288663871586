<template>
  <div class="payment-methods-wrap">
    <!-- Intro Banner
    ================================================== -->
    <!-- add class "disable-gradient" to enable consistent background overlay -->
    <div class="single-page-header" style="padding: 50px 0; min-height:0px">
      <div class="container">

        <!-- Intro Headline -->
        <div class="row">
          <div class="col-md-12">
            <div class="banner-headline">
              <h3>
                <strong style="font-size:50px;">Payment Methods</strong>
                <br>
                <p>Manage your list of secure payment sources</p>
              </h3>
            </div>
          </div>
        </div>

        <!-- Search Bar -->
        <div class="row">
          <div class="col-md-8">

            <!-- Button -->
            <div class="intro-search-button">
              <button class="button button-sliding-icon ripple-effect"
                      v-sliding-button
                      v-on:click="$refs.addPaymentModal.open()"
              >Add Payment <i><font-awesome-icon :icon="['far', 'credit-card']" /></i></button>
            </div>

          </div>
        </div>

      </div>
      <div class="background-image-container"
           :style="`background-image: url('${require('../../assets/header_wallet_short.png')}')`"></div>
    </div>

    <!-- Content
    ================================================== -->
    <!-- Features Jobs -->
    <div class="section padding-top-20 padding-bottom-65">
      <div v-if="paymentSourcesLoaded" class="container">

        <!-- Row -->
        <div v-if="paymentSources.length>0" class="row">

          <div class="col-xl-3 col-lg-4 hide-on-mobile">
            <div class="sidebar-container">


              <!-- Job Types -->
              <div class="sidebar-widget">
                <h3>Payment Type</h3>

                <div class="switches-list">
                  <div class="switch-container">
                    <label class="switch"><input v-model="showPaymentCards" type="checkbox"><span
                        class="switch-button"></span>
                      Credit / Debit Card
                    </label>
                  </div>

                  <div class="switch-container">
                    <label class="switch">
                      <input disabled type="checkbox"><span class="switch-button"></span>
                      Bank / ACH <span class="dashboard-status-button green">Coming Soon</span>
                    </label>
                  </div>

                </div>

              </div>

              <div class="clearfix"></div>

            </div>
          </div>

          <!-- Dashboard Box -->
          <div class="col-xl-9 col-lg-8">

            <h3 class="page-title">Saved Payments</h3>

            <div class="notify-box margin-top-15">
              <div class="switch-container">
                <label class="switch"><span class="switch-text padding-left-10">
                  Displaying <a href="#">{{filteredSources.length === this.paymentSources.length?'All' : filteredSources.length}}</a>
                  of {{this.paymentSources.length}} Payment Method{{this.paymentSources.length===1?'':'s'}}</span>
                </label>
              </div>

              <div class="sort-by">
                <span>Sort by:</span>
                <select v-select-picker
                        v-model="sortBy"
                        class=" hide-tick">
                  <option value="alphabetical">Alphabetical</option>
                  <option value="date" selected>Date Added</option>
                </select>
              </div>
            </div>

            <div class="dashboard-box margin-top-25">
              <div class="content">
                <ul class="dashboard-box-list">
                  <li v-for="source in filteredSources"
                      :key="source.funding_source_id"
                  >

                    <!-- Card Listing -->
                    <div class="job-listing">

                      <!-- Card Listing Details -->
                      <div class="job-listing-details">

                        <!-- Details -->
                        <div v-if="source.metadata" class="job-listing-description">
                          <h3 class="job-listing-title"><a href="#">{{source.metadata.brand}} {{source.metadata.last4}}
                            <span v-if="source.error_code" class="dashboard-status-button red"
                                  style="font-weight: normal">Failed</span>
                            <span
                                v-else-if="(new Date()).getFullYear()*1000+((new Date()).getMonth()+1) >source.metadata.exp_year*1000+source.metadata.exp_month"
                                class="dashboard-status-button red" style="font-weight: normal">Expired</span>
                            <span
                                v-else-if="(new Date()).getFullYear()*1000+((new Date()).getMonth()+1) == source.metadata.exp_year*1000+source.metadata.exp_month"
                                class="dashboard-status-button yellow" style="font-weight: normal">Expiring</span>
                          </a></h3>

                          <!-- Card Listing Footer -->
                          <div class="job-listing-footer">
                            <ul>
                              <li>Expires {{source.metadata.exp_month}}/{{source.metadata.exp_year}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Buttons -->
                    <div class="buttons-to-right always-visible">

                      <Dropdown>
                        <a href="#"
                           slot="trigger"
                           class="button"
                           v-ripple-dark
                           :title="'This card has ' + (source.recurringDonations.length + source.roundups.length) + ' active recurring donations'"
                        ><i><font-awesome-icon :icon="['far', 'repeat']"/></i> Donations
                          <div class="button-info">{{source.recurringDonations.length + source.roundups.length}}</div>
                        </a>
                        <div slot="content" class="dropdown-content">
                          <ul class="user-menu-small-nav">
                            <li>
                              <router-link :to="{name: 'MonthlyDonations'}">
                                <i><font-awesome-icon :icon="['far', 'calendar']"/></i> Monthly
                                <small class="text-secondary"
                                       style="font-style: italic"><b>{{source.recurringDonations.length}}</b></small>
                              </router-link>
                            </li>

                            <li>
                              <router-link
                                  :to="source.roundups.length?{name: 'AccountRoundUps',  query: {card: source.roundups[0].accountId}}:{name: 'AccountRoundUps'}">
                                <i><font-awesome-icon :icon="['far', 'arrow-alt-circle-up']"/></i> Round-Ups
                                <small v-if="source.roundups.length"
                                       class="text-secondary"
                                       style="font-style: italic"><b>On</b></small>
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </Dropdown>

                      <a href="#"
                         v-on:click.prevent="cardToDelete = source;$refs.deleteModal.open();"
                         class="button gray ripple-effect popup-with-zoom-anim padding-left-10 padding-right-10">
                        <font-awesome-icon :icon="['far', 'trash-alt']"/>
                      </a>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>

        </div>
        <p v-else>No Payment Sources</p>
        <!-- Row / End -->

      </div>
    </div>
    <!-- Featured Jobs / End -->

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="deleteModal"
                          :id="'small-dialog-1'"
                          v-on:open="isConfirmModal = false"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#tab1">Remove Card</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Tab -->
          <div class="popup-tab-content" id="tab1">

            <!-- Welcome Text -->
            <div class="welcome-text">
              <h3>Are you sure?</h3>
              <p class="margin-top-5">By removing this payment card, you'll also be deleting any
                <router-link :to="{name: 'MonthlyDonations'}" target="_blank">Monthly</router-link>
                &
                <router-link :to="{name: 'AccountRoundUps'}" target="_blank">Round-Up</router-link>
                donations on this card
              </p>
              <div v-if="cardToDelete && cardToDelete.metadata" class="remove-teammate">
                {{cardToDelete.metadata.brand}} {{cardToDelete.metadata.last4}}
              </div>
            </div>

            <form id="terms">
              <div class="checkbox">
                <input id="chekcbox2"
                       type="checkbox"
                       v-model="isConfirmModal"
                >
                <label for="chekcbox2"><span class="checkbox-icon"></span> Yes, remove this card</label>
              </div>
            </form>

            <!-- Button -->
            <button class="margin-top-15 button full-width button-sliding-icon ripple-effect"
                    :class="{ disabled: !isConfirmModal || isDeletingCard }"
                    :disabled="!isConfirmModal || isDeletingCard"
                    v-on:click.prevent="deleteCard(cardToDelete)"
            ><span v-if="isDeletingCard" class="loading-state">
              <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>Remove Card <i><font-awesome-icon :icon="['far', 'trash-alt']"/></i></button>

          </div>

        </div>
      </div>
    </magnific-popup-modal>

    <magnific-popup-modal :show="false"
                          :config="$root.popupConfig"
                          ref="addPaymentModal"
                          :id="'small-dialog-2'"
                          v-on:open="recaptchaShow = true"
                          v-on:close="recaptchaShow = false"
                          :class="'zoom-anim-dialog mfp-hide dialog-with-tabs'"
    >
      <!--Tabs -->
      <div class="sign-in-form">

        <ul class="popup-tabs-nav">
          <li><a href="#tabAddCard">Add Card</a></li>
        </ul>

        <div class="popup-tabs-container">

          <!-- Tab -->
          <div class="popup-tab-content" id="tabAddCard">

            <!-- Welcome Text -->

            <div class="welcome-text">
              <h3 style="font-size: 26px;">Add a Card</h3>
              <span> Enter your Debit/Credit Card Information</span>
            </div>

            <vgs-form
              class="margin-bottom-15"
              ref="vgsCardForm"
            ></vgs-form>

            <slide-up-down class="slide-up-down" :class="{'is-active': !!cardIsError}" :active="!!cardIsError">
              <div class="notification error margin-bottom-0">
                <p>{{cardAddError}}</p>
              </div>
            </slide-up-down>


            <button class="button full-width button-sliding-icon ripple-effect "
                    :disabled="isCardConnecting"
                    :class="{ disabled: isCardConnecting }"
                    v-on:click.prevent="addFundingSource"

            ><span v-if="isCardConnecting" class="loading-state">
              <font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/></span>
              Add Card <i><font-awesome-icon :icon="['far', 'credit-card']" /></i>
            </button>


          </div>

        </div>
      </div>
    </magnific-popup-modal>

    <vue-recaptcha v-if="recaptchaShow"
                   :sitekey="$root.config.google.recaptcha_key_invisible ? $root.config.google.recaptcha_key_invisible : '6Ld0WrsUAAAAAOawnDvZtuFRpPtwuV7ptlpxJJjW'"
                   class="margin-bottom-15"
                   :loadRecaptchaScript="true"
                   ref="recaptcha"
                   size="invisible"
                   @verify="onVerifyInvisible($event, $refs.vgsCardForm)"
                   @expired="onExpired"
    ></vue-recaptcha>

  </div>
</template>

<script>
  import {fundingSource, recurringDonation, roundupAccount} from "../../utils/api";
  import MagnificPopupModal from '../Parts/MagnificPopupModal'
  import Dropdown from "../Dropdown";
  import {showNotification} from "../../utils/utils";
  import recaptchaMixin from '../Donations/recaptcha-mixin'
  import SlideUpDown from 'vue-slide-up-down'
  import VueRecaptcha from 'vue-recaptcha';
  import VgsForm from './vgs-form-2.vue';
  import vgsMixin from './vgs-mixin';

  export default {
    name: 'PaymentMethods',
    props: ['isLogin', 'donorDetail', 'charityInfo'],
    metaInfo() {
      return {
        title: 'Payment Methods',
      }
    },
    mixins: [recaptchaMixin, vgsMixin],
    components: {
      MagnificPopupModal,
      Dropdown,
      SlideUpDown,
      VueRecaptcha,
      VgsForm,
    },
    mounted() {
      this.loadCards();
    },
    methods: {
      loadCards() {
        Promise.all([
          recurringDonation.getRecurringDonations(),
          fundingSource.getAllFundingSources(),
          roundupAccount.getAllRoundupAccounts()
        ])
          .then(([donations, sources, roundups]) => {
            this.recurringDonations = donations;
            this.paymentSourcesLoaded = true;
            this.paymentSources = sources;
            this.roundups = roundups;
          });
      },
      addFundingSource() {
        this.vgsAddFundingSource(this.$refs.vgsCardForm);
      },
      deleteCard(cardToDelete) {
        // this.$refs.deleteModal.close();
        this.isDeletingCard = true;
        if (cardToDelete.recurringDonations && cardToDelete.recurringDonations.length) {
          Promise.all(cardToDelete.recurringDonations.map(item => recurringDonation.deleteRecurringDonation(item.subscriptionId)))
            .then(() => {
              cardToDelete.recurringDonations = [];
              this.deleteCard(cardToDelete);
            })
            .catch(delErr => {
              this.isDeletingCard = false;
              alert(delErr.responseJSON.error.replace('funding_source_id', 'funding source'));
            });
        } else if (cardToDelete.roundups && cardToDelete.roundups.length) {

          Promise.all(cardToDelete.roundups.map(item => roundupAccount.deleteRoundupAccount(item)))
            .then(() => {
              cardToDelete.roundups = [];
              this.deleteCard(cardToDelete);
            })
            .catch(delErr => {
              this.isDeletingCard = false;
              alert(delErr.responseJSON.error.replace('funding_source_id', 'funding source'));
            });
        } else {

          fundingSource.deleteFundingSource(cardToDelete.funding_source_id)
            .then(() => {
              this.isDeletingCard = false;
              showNotification("Payment method has been deleted", this.charityInfo.primary_color);
              this.$refs.deleteModal.close();
              this.loadCards();
            })
            .catch(delErr => {

              this.isDeletingCard = false;
              alert(delErr.responseJSON.error.replace('funding_source_id', 'funding source'));
            });
        }
      },
    },
    computed: {
      allSources() {
        let paymentSources = JSON.parse(JSON.stringify(this.paymentSources));

        paymentSources = paymentSources.map(item => {
          return {
            ...item,
            recurringDonations: this.recurringDonations.filter(recurring => recurring.platformFundingSourceId === item.funding_source_id),
            roundups: this.roundups.filter(rAccount => {
              if(rAccount.fundingSourceId === item.funding_source_id){
                return true;
              }
              if(rAccount.account_type === "card-linked" && rAccount.fundingSourceId == item.id){
                return true;
              }
              return false;
            }),
          };
        });

        return paymentSources;
      },
      filteredSources() {
        let allSources = Array.from(this.allSources);

        allSources = allSources.filter(source => this.showPaymentCards ? source.type === 'card' : source.type !== 'card');

        if (this.sortBy && this.sortBy === 'alphabetical') {
          allSources.sort((a, b) => {
            if (!a.metadata || !b.metadata) {
              return 0;
            }
            return `${a.metadata.brand} ${a.metadata.last4}`.localeCompare(`${b.metadata.brand} ${b.metadata.last4}`)
          });
        } else {
          allSources = allSources.reverse();
        }

        if (this.searchFilter) {
          const re = new RegExp(this.searchFilter, 'ig');
          allSources = allSources.filter(v => v.metadata && `${v.metadata.brand} ${v.metadata.last4}`.search(re) !== -1);
        }

        return allSources;
      }
    },
    data() {
      return {
        paymentSourcesLoaded: false,
        recurringDonations: [],
        paymentSources: [],
        searchFilter: '',
        roundups: [],
        showPaymentCards: true,
        cardToDelete: null,
        sortBy: 'date',
        isConfirmModal: false,

        isDeletingCard: false
      };
    },
  }
</script>
